var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <td style=\"color:#bbb;line-height: 20px;letter-spacing: 1.7px;padding-right: 5px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.remitEmail),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyURL),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "          <strong>Milestone Computer Technology</strong>\n        ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress), depth0)) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "          314 Wilcox St.\n        ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState), depth0)) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "          Castle Rock, CO 80104\n        ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.remitEmail), depth0)) != null ? stack1 : "")
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "          billing@milestonetech.com\n        ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyURL), depth0)) != null ? stack1 : "")
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "          www.milestonetech.com<br/>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize), depth0));
},"24":function(container,depth0,helpers,partials,data) {
    return "26";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.LOGOSRC)) && stack1.length),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":14},"end":{"line":49,"column":21}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <td align=\"right\" colspan=\"2\" style=\"border-radius: 50%; padding-right: 30px;\"><img src="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.LOGOSRC), depth0))
    + " style=\"height: 75px;\"/></td>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showCustomerName),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":52,"column":12}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <td align=\"right\" colspan=\"2\" style=\"border-radius: 50%; padding-right: 30px; font-size: 25px; font-weight: 600;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "</td>\n            ";
},"32":function(container,depth0,helpers,partials,data) {
    return "              <td align=\"right\" colspan=\"2\"><span style=\"padding: 10px; background-color: #cdcdcd; text-align:center; color: white;\">Customer Indicator</span></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_header\" style=\"padding-top: 10px !important;padding-left: 10px !important; padding-right: 10px !important;\">\n  <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showRemitAddress),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "    <td colspan=\"2\"><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" /></td>\n  </tr>\n  <tr>\n    <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 5px;letter-spacing: 1.7px;\">\n    </td>\n  </tr>\n  <tr>\n    <td colspan=\"3\">\n      <table width=\"100%\">\n        <tr>\n          <td style=\"padding: 20px 0;font-size: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":42,"column":48},"end":{"line":42,"column":141}}})) != null ? stack1 : "")
    + "px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\">\n            <span style=\"color: #0196d5;\">INVOICE</span>\n          </td>\n          <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showLogo),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":52,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showTempIndicator),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":55,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n      </table>\n    </td>\n  </tr>\n</table>\n";
},"useData":true});