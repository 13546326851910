























































































































import { VNode } from "vue";
import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import SecurityMultiSelect from "../components/SecurityMultiSelect.vue";
import AccountMultiselect from "../components/AccountMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {
}

interface Events {
    onClose: void
    onSave: void
}

declare const $: any
declare const getImageRouteData: Function;

@Component({
    inheritAttrs: false,
    components: {
        LaddaButton,
        AccountMultiselect,
        SecurityMultiSelect,
        DropdownControl
    }
})
export default class HardwaresImportPreviewModal extends TSXComponent<
    Props,
    Events
> {

    @Prop({ required: true })
    confirmList!: any[];

    saving = false;
    loading: boolean = false;

    $refs!: {
        modal: HTMLDivElement
    }

    mounted() {
        $(this.$refs.modal).modal('show');

        $(this.$refs.modal).on('hide.bs.modal', () => {
            this.$emit('close')
        });

        for (const item of this.confirmList) {
            if (item.dbColumnId === 'WarrantyExp') {
                this.initDatePicker(item);
            }
        }
    }

    initDatePicker(item) {
        let html = "";
        html += `<div id="datepicker${item.lineNo}" class="datepicker-container">`;
        html +=
            '<div class="datepicker-panel" data-view="years picker"><ul class="fr-current"><li data-view="years prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="years current"></li><li data-view="years next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="years"></ul></div>';
        html +=
            '<div class="datepicker-panel" data-view="months picker"><ul class="fr-current"><li data-view="year prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="year current"></li><li data-view="year next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="months"></ul></div>';
        html +=
            '<div class="datepicker-panel" data-view="days picker"><ul class="fr-current"><li data-view="month prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="month current"></li><li data-view="month next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="week"></ul><ul data-view="days"></ul></div>';
        html += "</div>";

        $(`#warrantyExp${item.lineNo}`).datepicker({
            autoHide: true,
            zIndex: 2048,
            daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            template: html
        });

        $(document).on("change", `#warrantyExp${item.lineNo}`, e => {
            item.updateValue = $(`#warrantyExp${item.lineNo}`)
                .val()
                .trim();
        });
    }

    beforeDestroy() {
        $(this.$refs.modal).modal('hide')
    }

    onSkipFlgChange(mapItem) {
        if (mapItem.skipFlg === 1) {
            console.log('map item ', mapItem);
            mapItem.skipFlg = 0;
            if (mapItem.dbColumnId === 'SKU') {
                mapItem.updateValue = mapItem.generatedValue;
            } else if (mapItem.dbColumnId === 'SerialNumber') {
              mapItem.updateValue = mapItem.generatedValue;  
            } else {
                mapItem.updateValue = mapItem.updateOriginValue;
            }
        } else {
            mapItem.skipFlg = 1;
        }

        // update related item row
        for (const map of this.confirmList) {
            if (map.lineNo === mapItem.lineNo) {
                if (map.csvColumnId !== mapItem.csvColumnId && map.dbColumnId !== mapItem.dbColumnId) {
                    map.skipFlg = mapItem.skipFlg;
                }
            }
        }
    }

    async save() {
        const valid = await this.$validator.validateAll();

        if (!valid) {
            return;
        }
        this.saving = true;

        this.$emit('save', this.confirmList);
    }
}
