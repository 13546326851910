





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import moment from "moment";

import Pagination from "../components/Pagination.vue";

import {
  QuoteAPIRepo,
  Filters,
  Sort,
  SortField,
  QuoteStatus,
  ResellerCheckList
} from "../repos/QuoteAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import UpdateItemStatusModal from "../components/UpdateItemStatusModal.vue";
// import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue"
import QuoteStatusComponent, {
  QuoteStatusValue
} from "../components/QuoteStatus.vue";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import { Quote } from "../models/Quote";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import FilterModal from "../components/FilterModal.vue";
import {
  colsResizeable,
  colsResizeableReset,
  colsResizeableUpdate,
  getDateRangeFilter,
  priceTypeStr
} from "@/helpers/ApiHelper";
import ModalProductCatalog from "@/components/Quote/ProductCatalog.vue";
import ProductCatalog from "@/components/Quote/ProductCatalog.vue";
import draggable from "vuedraggable";

declare function require(params: any): any;
declare const dataURL: string;
declare const $: any;

const template = require("../templates/quotes/quoteList.handlebars");

const quoteRepo = new QuoteAPIRepo();
const userRepo = new UserAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    ProductCatalog,
    ModalProductCatalog,
    Pagination,
    draggable,
    UpdateItemStatusModal,
    // ConfirmRemoveItemModal,
    QuoteStatusComponent,
    FilterCurrencyBox,
    ExportModal,
    CustomerSearchTooltip,
    FilterModal
  },
  methods: {
    priceTypeStr,
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class Quotes extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $userFunctions: any;
	$isHideReseller: any;
  selectedID: string[] = [];
  selectedQuotes: any = [];
  customVersion = false;
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  viewType = "CurrentUsers";
  showFilter = 0;
  quotesData: Quote.Root | any = {};
  filters: any = {
    searchAll: "",
    filterStatusName: [],
    searchId: "",
    searchName: "",
    HpQuote: "",
    searchAccountName: "",
    sDate: undefined,
    eDate: undefined,
    sGrandTotalMin: undefined,
    sGrandTotalMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sStatus: [],
    SSURL: [],
    searchSSURL: '',
    resellerCheckList: [],
    qOpenStatus: this.currentRole === "Reseller" ? [] : [0, 1, 5, 6, 8],
    AccountTagID: [],
    gTagIDs: [],
    groupIDs: [],
    sSource: [],
    selectedAccs: [],
    // accountTagName: [],
    selectedAccountsName: [],
    // groupName: [],
    filterTotal: undefined,
    filterGrandTotal: undefined,
    filterQuoteDate: undefined,
    sourceName: [],
    deptName: "",
    businessLineId: [],
    businessLineName: [],
    quoteCreator: "",
    varUserIds: []
  };
  sort: any = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.Name]: 1,
      [SortField.Customer]: 1,
      [SortField.GrandTotal]: 1,
      [SortField.Date]: 1,
      [SortField.qOpenStatus]: 1,
      [SortField.Source]: 1,
      [SortField.Total]: 1,
      [SortField.DepartmentName]: 1,
      [SortField.system]: 1,
      [SortField.businessLineName]: 1
    }
  };
  position = {
    filterStatusName: 1,
    searchId: 2,
    searchName: 3,
    searchAccountName: 4,
    accountTagName: 5,
    selectedAccountsName: 6,
    groupName: 7,
    filterTotal: 8,
    filterGrandTotal: 9,
    filterQuoteDate: 10,
    sourceName: 11,
    HpQuote: 12,
    deptName: 13,
    businessLineName: 14,
    SSURL: 15,
  };
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["Quotes"] ? JSON.parse(sessionStorage.pageDetails)["Quotes"] : 1 ;
  items: Quote.Quotes[] = [];

  updateStatusModalVisible = false;
  confirmRemoveModalVisible = false;
  exportModalVisible = false;
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  resellers = [];
  selectedAll = false;
  excludedIDs: string[] = [];
  selectedView = false;
  curYear = new Date().getFullYear();
  lYear = this.curYear - 1;
  CURRENTYEAR = 0;
  LASTYEAR = 0;
  accountTags: any = [];
  searchTagsTxt = "";
  searchAccTxt = "";
  selectedStatuses = false;
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  pageHeaders = [
    { key: "QOPEN", value: "Status" },
    { key: "QUOTEID", value: "Quote Number" },
    { key: "QDESCRIPTION", value: "Quote Name" },
    { key: "ACCOUNTNAME", value: this.currentRole === "Customer" ? "Reseller" : "Customer", },
    { key: "ACCOUNTNAMEFORCUSTOMER", value: "Customer Name" },
    { key: "TOTALFORMATTED", value: "SubTotal" },
    { key: "GRANDTOTALFORMATTED", value: "Grand Total" },
    { key: "NEWDATEFORMATTED", value: "Date" },
    { key: "SOURCE", value: "Source" },
    { key: "QUOTECREATOR", value: "Quote Creator" },
    { key: "HPQuoteNumber", value: "Source Quote ID" },
    { key: "DEPARTMENTNAME", value: "Department Name" },
    { key: "SSURL", value: "System" },
    { key: "BUSINESSLINENAME", value: "Business Line" },
  ];
  pageCustomerHeaders = [
    { key: "QOPEN", value: "Status" },
    { key: "QUOTEID", value: "Quote Number" },
    { key: "HPQuoteNumber", value: "Source Quote ID" },
    { key: "QDESCRIPTION", value: "Quote Name" },
    { key: "ACCOUNTNAMEFORCUSTOMER", value: "Customer Name" },
    { key: "GRANDTOTALFORMATTED", value: "Grand Total" },
    { key: "NEWDATEFORMATTED", value: "Date" },
  ];
  headerOrder = [
    "QOPEN",
    "QUOTEID",
    "QDESCRIPTION",
    "ACCOUNTNAME",
    "ACCOUNTNAMEFORCUSTOMER",
    "TOTALFORMATTED",
    "GRANDTOTALFORMATTED",
    "NEWDATEFORMATTED",
    "SOURCE",
    "QUOTECREATOR",
    "HPQuoteNumber",
    "DEPARTMENTNAME",
    "SSURL",
    "BUSINESSLINENAME"
  ];
  selectedheaders = [
    "QOPEN",
    "QUOTEID",
    "QDESCRIPTION",
    "ACCOUNTNAME",
    "ACCOUNTNAMEFORCUSTOMER",
    "GRANDTOTALFORMATTED",
    "NEWDATEFORMATTED"
  ];
  exportHeadersArr: {
    header: string;
    column: string;
    widthPercent: string;
  }[] = [];
  selectedTags = [];
  CurrentView = "My Quotes";
  tagIDs: number[] = [];
  // gTagIDs: number[] = [];
  filterModalVisible = false;
  usedFilters = {
    filterStatusName: {
      header: "Status",
      function: "resetqOpenStatusList"
    },
    SSURL: {
      header: "System",
      function: "resetSystemList"
    },
    searchId: {
      header: "Quote Number",
      function: "resetSearchById"
    },
    searchName: {
      header: "Quote Name",
      function: "resetSearchByName"
    },
    searchAccountName: {
      header: "Customer",
      function: "resetSearchByAccountName"
    },
    accountTagName: {
      header: "Customer Tags",
      function: "resetTag"
    },
    selectedAccountsName: {
      header: "Customer Accounts",
      function: "resetAcc"
    },
    groupName: {
      header: "Customer Groups",
      function: "resetTag"
    },
    filterTotal: {
      header: "Total",
      function: "resetSearchBySubTotal"
    },
    filterGrandTotal: {
      header: "Grand Total",
      function: "resetSearchByTotal"
    },
    filterQuoteDate: {
      header: "Date",
      function: "resetSearchByDate"
    },
    sourceName: {
      header: "Source",
      function: "resetSourceList"
    },
    HpQuote: {
      header: "Source Quote ID",
      function: "resetSearchByHPId"
    },
    deptName: {
      header: "Department Name",
      function: "resetSearchByDeptName"
    },
    businessLineName: {
      header: "Business Line",
      function: "resetBusinessLineList"
    }
  }
  quoteStartDate: (string | undefined);
  quotedueEndDate: (string | undefined);
  usedFilterDetails: any = [];
  searchFilters: any = {
    searchAll: "",
    filterStatusName: [],
    searchId: "",
    searchName: "",
    HpQuote: "",
    searchAccountName: "",
    sDate: undefined,
    eDate: undefined,
    sGrandTotalMin: undefined,
    sGrandTotalMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sStatus: [],
    resellerCheckList: [],
    qOpenStatus: this.currentRole === "Reseller" ? [] : [0, 1, 5, 6, 8],
    AccountTagID: [],
    gTagIDs: [],
    groupIDs: [],
    sSource: [],
    selectedAccs: [],
    // accountTagName: [],
    selectedAccountsName: [],
    groupName: [],
    filterTotal: undefined,
    filterGrandTotal: undefined,
    filterQuoteDate: undefined,
    sourceName: [],
    deptName: "",
    businessLineId: [],
    businessLineName: [],
    quoteCreator: ""
  };
  quotesCheckAll = false;
  // listv2 = 0
  listCustomFields: any = [];
  searchHeader = "";
  varUsers: any = [];
  hasChangedAccountFilters = false;
  hasChangedSourceFilters = false;
  hasChangedStatusFilters = false;
  hasChangedBusinessLineFilters = false;
  hasChangedVarUserIds = false;
  searchQuoteCreator = "";

  async created() {

    if(this.currentRole === "Reseller") {
      this.pageHeaders.splice(this.pageHeaders.findIndex(item => item.key == 'ACCOUNTNAMEFORCUSTOMER'), 1);
    }

    if (this.currentRole === "Customer" && this.$isHideReseller) {
      this.selectedheaders.splice(this.selectedheaders.indexOf('ACCOUNTNAME'), 1);
    }

    if (typeof this.$route.query.searchAll === "string") {
      this.filters.searchAll = this.$route.query.searchAll;
    }
    if (typeof this.$route.query.searchId === "string") {
      this.filters.searchId = this.searchFilters.searchId = this.$route.query.searchId;
    }
    if (typeof this.$route.query.searchName === "string") {
      this.filters.searchName = this.searchFilters.searchName = this.$route.query.searchName;
    }
    if (typeof this.$route.query.searchAccountName === "string") {
      this.filters.searchAccountName = this.searchFilters.searchAccountName = this.$route.query.searchAccountName;
    }
    if (typeof this.$route.query.sDate === "string") {
      this.filters.sDate = this.searchFilters.sDate = this.$route.query.sDate;
    }
    if (typeof this.$route.query.eDate === "string") {
      this.filters.eDate = this.searchFilters.eDate = this.$route.query.eDate;
    }
    if (typeof this.$route.query.sGrandTotalMin === "string") {
      this.filters.sGrandTotalMin = this.searchFilters.sGrandTotalMin = this.$route.query.sGrandTotalMin;
    }
    if (typeof this.$route.query.sGrandTotalMax === "string") {
      this.filters.sGrandTotalMax = this.searchFilters.sGrandTotalMax = this.$route.query.sGrandTotalMax;
    }
    if (typeof this.$route.query.sTotalMin === "string") {
      this.filters.sTotalMin = this.searchFilters.sTotalMin = this.$route.query.sTotalMin;
    }
    if (typeof this.$route.query.sTotalMax === "string") {
      this.filters.sTotalMax = this.searchFilters.sTotalMax = this.$route.query.sTotalMax;
    }
    // if (typeof this.$route.query.quoteCreator === "string") {
    //   this.filters.quoteCreator = this.searchFilters.quoteCreator = this.$route.query.quoteCreator;
    // }
    if (
      typeof this.$route.query.varUserIds == "string" &&
      parseInt(this.$route.query.varUserIds)
    ) {
      this.filters.varUserIds = [parseInt(this.$route.query.varUserIds)];
    } else if (
      Array.isArray(this.$route.query.varUserIds) &&
      this.$route.query.varUserIds.length
    ) {
      this.filters.varUserIds = this.$route.query.varUserIds
        .filter(id => parseInt(`${id}`))
        .map(id => parseInt(`${id}`));
    }

    if (typeof this.$route.query.sStatus === "string") {
      this.filters.sStatus = [parseInt(this.$route.query.sStatus)];
    } else if (Array.isArray(this.$route.query.sStatus)) {
      this.filters.sStatus = this.$route.query.sStatus.map(s =>
        parseInt(s || "0")
      );
    }
    // if (typeof this.$route.query.resellerCheckList === 'string') {
    //   this.filters.resellerCheckList = JSON.parse(this.$route.query.resellerCheckList)
    // }

    if (typeof this.$route.query.qOpenStatus === "string") {
      this.filters.qOpenStatus = [parseInt(this.$route.query.qOpenStatus)];
    } else if (Array.isArray(this.$route.query.qOpenStatus)) {
      this.filters.qOpenStatus = this.$route.query.qOpenStatus.map(s =>
        parseInt(s || "0")
      );
    }
    if (this.filters.qOpenStatus.length) {
      this.selectedStatuses = true;
    }

    if (typeof this.$route.query.filterView === "string") {
      this.viewType = this.$route.query.filterView;
      this.selectedView = true;
    } else if (typeof this.$route.query.filterView === "number") {
      if (this.$route.query.tagIDs && Array.isArray(this.$route.query.tagIDs)) {
        this.filters.AccountTagID = this.$route.query.tagIDs.map(s =>
          parseInt(s || "0")
        );
      }
      // this.filters.AccountTagID.push(this.$route.query.filterView);
    }

    if (typeof this.$route.query.AccountTagID == "string") {
      this.filters.AccountTagID = [parseInt(this.$route.query.AccountTagID)];
    } else if (Array.isArray(this.$route.query.AccountTagID)) {
      this.filters.AccountTagID = this.$route.query.AccountTagID.map(s =>
        parseInt(s || "0")
      );
    }

    //switch to all company view if redirect from dashboard (case: selected a tag)
    if (typeof this.$route.query.viewFrom != "undefined") {
      this.filters["viewFrom"] = this.$route.query.viewFrom;
      if (
        (this.$allowedFunctions.includes(
          this.$userFunctions.All_Milestone_Accounts
        ) &&
          this.filters["viewFrom"] == "dashboard" &&
          typeof this.$route.query.filterView === "number") ||
        this.filters["viewFrom"] == "cDetails"
      ) {
        this.viewType = "All";
        this.selectedView = true;
      }
      // if(typeof this.$route.query.selectedGlobalTag != "undefined" && this.$route.query.selectedGlobalTag) {
      //   this.filters["selectedGlobalTag"] = this.$route.query.selectedGlobalTag;
      // }
      if (
        typeof this.$route.query.gTagIDs != "undefined" &&
        Array.isArray(this.$route.query.gTagIDs) &&
        this.$route.query.gTagIDs.length
      ) {
        this.filters.gTagIDs = this.$route.query.gTagIDs.map((s) =>
        parseInt(s || "0")
        );
      }
      if (
        typeof this.$route.query.groupIDs != "undefined" &&
        Array.isArray(this.$route.query.groupIDs) &&
        this.$route.query.groupIDs.length
      ) {
        this.filters.groupIDs = this.$route.query.groupIDs.map((s) =>
        parseInt(s || "0")
        );
      }
      if (typeof this.$route.query.businessLineId === "string") {
        this.filters.businessLineId = this.searchFilters.businessLineId = [this.$route.query.businessLineId];
      } else if (Array.isArray(this.$route.query.businessLineId)) {
        this.filters.businessLineId = this.$route.query.businessLineId;
      }
      if (typeof this.$route.query.businessLineName === "string") {
        this.filters.businessLineName = this.searchFilters.businessLineName = [this.$route.query.businessLineName];
      } else if (Array.isArray(this.$route.query.businessLineName)) {
        this.filters.businessLineName = this.$route.query.businessLineName;
      }
    }

    await this.fetchData(true);
    this.accsLoad();
    if (this.currentRole === "Customer") {
      const response = await userRepo.getResellers();
      this.resellers = response.RESELLERS;
    }

    // console.dir(this);

    // this.testListv2();
    colsResizeable({ms: 500});
  }

  setDefaultRouteQuery(isWaiting) {
    if (isWaiting) {
      this.filters.qOpenStatus = this.searchFilters.qOpenStatus = [8];
    } else {
      this.filters.qOpenStatus = this.searchFilters.qOpenStatus = this.currentRole === "Reseller" ? [] : [0, 1, 5, 6, 8];
    }
    this.pageNumber = 1;
  }

  @Watch("selectedheaders")
  colsChange(val: any) {
    colsResizeable();
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }

  get isSearch() {
    return Object.keys(this.query).length > 0;
  }
  
  async fetchData(initialLoad: boolean = false) {
    this.hasChangedAccountFilters = false;
    this.hasChangedStatusFilters = false;
    this.hasChangedSourceFilters = false;
    this.hasChangedBusinessLineFilters = false;
    this.hasChangedVarUserIds = false;
    this.hasChangedSystemFilters = false;

    if (!initialLoad) {
      this.$router
        .replace({ name: this.$route.name, query: this.query })
        .catch(tmp => {});
    }
    // this.filters.AccountTagID = this.filters.AccountTagID.concat(this.tagIDs)
    this.loading = true;
    // storing filter details in session for go back filter result
    if (JSON.parse(sessionStorage.filterDetails)["Quotes"]) {
      var exisitngFilterDetails = JSON.parse(sessionStorage.filterDetails)
      exisitngFilterDetails["Orders"] = {};
      exisitngFilterDetails["Quotes"] = this.filters;
      exisitngFilterDetails["Invoices"] = {};
      exisitngFilterDetails["Opportunities"] = {};
      if (this.query != "undefined" && Object.keys(this.query).length != 0) {
        sessionStorage.setItem('filterDetails',JSON.stringify(exisitngFilterDetails));
      }
    }
    if((this.query == "undefined" || (this.query != "undefined" && Object.keys(this.query).length == 0)) && Object.keys(JSON.parse(sessionStorage.filterDetails).Quotes).length != 0){
      this.filters = this.searchFilters = JSON.parse(sessionStorage.filterDetails).Quotes;
      this.filters = {...this.filters, searchSSURL: this.filters.searchSSURL || ''};
    }
      const quotesData: any = await quoteRepo.find(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        this.selectedStatuses,
        initialLoad
      );
      this.quotesData = quotesData;

      this.varUsers = quotesData.varUsers || [];
      var date = new Date().getFullYear();
      this.curYear = date;
      this.lYear = date - 1;
      this.CURRENTYEAR = quotesData.STAT[0][date];
      this.LASTYEAR = quotesData.STAT[1][date - 1];
      this.customerTooltipVisibleIndex = -1;
      if (
        quotesData.UserQuoteHeaders &&
        quotesData.UserQuoteHeaders.length &&
        this.currentRole != "Customer"
      ) {
        this.selectedheaders = quotesData.UserQuoteHeaders;
      }
      // $(".actionsBtn").addClass('gray').removeClass('teal');  To comment this line is, issue for - Selected counts not shows in action button
      // this.selectedID = [];

      this.items = quotesData.QUOTES;

      // console.log(this.items);

      if (this.searchTagsTxt == "") {
        // this.accountTags = quotesData.accountTags;
        this.accountTags = []; // reset tags list
        for (let i = 0; i < quotesData.accountTags.length; i++) {
            if(quotesData.accountTags[i].ISGLOBALTAG) {
              if(quotesData.accountTags[i].FORQUOTES) {
                this.accountTags.push(quotesData.accountTags[i]);
              }
            } else {
              this.accountTags.push(quotesData.accountTags[i]);
            }
          }
      }
      if (this.filters.AccountTagID.length || this.filters.gTagIDs.length || this.filters.groupIDs.length) {
        // this.selectedView = true;
        this.CurrentView = this.getCurrentViewTitle();
        // if(!(this.filters.groupIDs && this.filters.groupIDs.length)) {
        //  this.viewType = "1"; //just set a number
        // }
      } else {
        // reset
        // this.selectedView = false;
      }
      // this.CurrentView = this.getCurrentViewTitle();
      // this.varAccs = quotesData.VARACCOUNTS;
      // this.selectedAccs = quotesData.VARACCOUNTSIDSRR;

      if (
        !this.selectedView &&
        typeof quotesData["userSelectedCurrentView"] != "undefined"
      ) {
        this.selectedView = true;
        this.viewType = quotesData["userSelectedCurrentView"];
        this.CurrentView =
          this.viewType == "All" ? "Company Quotes" : "My Quotes";
      }

      if (
        !this.selectedStatuses &&
        typeof quotesData["userSelectedStatuses"] != "undefined"
      ) {
        this.selectedStatuses = true;
        this.filters.qOpenStatus = quotesData["userSelectedStatuses"] ? quotesData["userSelectedStatuses"]
          .split(",")
          .map(val => parseInt(val)) : [];
      }
      if(typeof this.quotesData.sourcesObj != 'undefined' && this.filters.sSource.length == 0 && initialLoad) {
        this.filters.sSource = Object.keys(this.quotesData.sourcesObj);
        this.filters.sourceName = Object.values(this.quotesData.sourcesObj);
      }
      if(typeof this.quotesData.businessLinesObj != 'undefined' && this.filters.businessLineId.length == 0 && initialLoad) {
        this.filters.businessLineId = Object.keys(this.quotesData.businessLinesObj);
        this.filters.businessLineName = Object.values(this.quotesData.businessLinesObj);
      }

      if (this.quotesData.SYSTEMS && !this.filters.SSURL.length && initialLoad) {
        this.filters.SSURL = (this.quotesData.SYSTEMS || []).map((system) => { return system.SSID; });
      }

      // append custom fields to custom colums list
      this.listCustomFields = quotesData.listCustomFields || [];
      for(const item of this.listCustomFields) {
        const field = `customData${item.CUSTOMFIELDID}`;
        let existedName = false;
        for (const header of this.pageHeaders) {
          if (
              header.key.indexOf("customData") != -1 &&
              header.value.toLowerCase() ==
              item.CUSTOMFIELDNAME.toLowerCase()
          ) {
            existedName = true;
            break;
          }
        }
        if (!existedName) {
          if ((this.pageHeaders.find((header) => header.key == field) || { key: '', value: ''}).value == "") {
            this.pageHeaders.push({
              key: field,
              value: item.CUSTOMFIELDNAME
            });
          }
          if (!this.headerOrder.includes(field)) {
            this.headerOrder.push(field);
          }
        }
      }

      // if sorted header
      const savedSorting = this.quotesData.userQuoteColumnOrder || [];
      if (savedSorting.length) {
        let newSorting: any = [];
        for (const header of savedSorting) {
          const inList = this.pageHeaders.find(item => item.key == header);
          if (inList) {
            newSorting.push(inList);
          }
        }
        const newItems = this.pageHeaders.filter(
            item => !savedSorting.includes(item.key)
        );
        newSorting = [...newSorting, ...newItems];
        this.pageHeaders = newSorting;

        // need to sort selectedHeaders
        this.sortSelectedHeader();
      }

      // make sure values of this.selectedheaders existed in pageHeaders
      this.selectedheaders = this.selectedheaders.filter(
          field => (this.pageHeaders.find((headerItem) => headerItem.key == field) || { key: '', value: '' }).key != ""
      );




    if (
      this.filters.eDate != undefined ||
      this.filters.qOpenStatus.length != 0 ||
      this.filters.resellerCheckList.length != 0 ||
      this.filters.sDate != undefined ||
      this.filters.sStatus.length != 0 ||
      this.filters.sGrandTotalMax != undefined ||
      this.filters.sGrandTotalMin != undefined ||
      this.filters.sTotalMax != undefined ||
      this.filters.sTotalMin != undefined ||
      this.filters.searchAccountName != "" ||
      this.filters.searchAll != "" ||
      this.filters.searchId != "" ||
      this.filters.searchName != "" ||
      this.filters.deptName != ""
    ) {
      this.showFilter = 1;
    } else {
      this.showFilter = 0;
    }
    if (this.filters.qOpenStatus.length == 6) {
      this.showFilter = 0;
    }
    if (this.selectedAll === true) {
      this.selectAll(true);
    }

    if(typeof this.quotesData.QUOTESTATUSNAME != 'undefined') {
      this.filters.filterStatusName = this.quotesData.QUOTESTATUSNAME.split(',');
    }
    if(this.filters['sDate'] != undefined){
      this.quoteStartDate = moment(this.filters['sDate']).format("MM/DD/YYYY");
    }
    if(this.filters['eDate'] != undefined){
      this.quotedueEndDate = moment(this.filters['eDate']).format("MM/DD/YYYY");
    }
    if(this.quoteStartDate != undefined && this.quotedueEndDate != undefined){
      this.filters['filterQuoteDate'] = this.quoteStartDate + ' - ' + this.quotedueEndDate;
    } else if(this.quoteStartDate != undefined) {
      this.filters['filterQuoteDate'] = this.quoteStartDate;
    } else if(this.quotedueEndDate != undefined){
      this.filters['filterQuoteDate'] = this.quotedueEndDate;
    }
    
    if(this.filters['sTotalMin'] != undefined && this.filters['sTotalMax'] != undefined){
      this.filters['filterTotal'] = '$' + this.filters['sTotalMin'] + ' - ' + this.filters['sTotalMax'];
    } else if(this.filters['sTotalMin'] != undefined) {
      this.filters['filterTotal'] = '$' + this.filters['sTotalMin'] + '+';
    } else if(this.filters['sTotalMax'] != undefined){
      this.filters['filterTotal'] = '$' + this.filters['sTotalMax'] + '-';
    }
    if(this.filters['sGrandTotalMin'] != undefined && this.filters['sGrandTotalMax'] != undefined){
      this.filters['filterGrandTotal'] = '$' + this.filters['sGrandTotalMin'] + ' - ' + this.filters['sGrandTotalMax'];
    } else if(this.filters['sGrandTotalMin'] != undefined) {
      this.filters['filterGrandTotal'] = '$' + this.filters['sGrandTotalMin'] + '+';
    } else if(this.filters['sGrandTotalMax'] != undefined){
      this.filters['filterGrandTotal'] = '$' + this.filters['sGrandTotalMax'] + '-';
    }
    this.usedFilterDetails = this.query;
    // console.log(this.items);

    this.loading = false;
  }

  //customerTooltipVisibleIndex = -1
  //customerTooltipLoading = false
  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      // const subAccounts = await CustomerRepo.findOneFewDetails(item.SECURITYGROUPID, FewDetailsJustGet.Users)
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }
  // selectallStatus() {
  //   this.selectedID = this.quotesData.quoteIds;
  //   $('.actionsBtn').removeClass('gray').addClass('teal');
  // }
  // UnselectallStatus() {
  //   this.selectedID = [];
  //   $('.actionsBtn').addClass('gray').removeClass('teal');
  // }
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingByqOpenStatus() {
    this.sortBy(SortField.qOpenStatus);
  }

  async sortingById() {
    this.sortBy(SortField.Id);
  }

  async sortingByName() {
    this.sortBy(SortField.Name);
  }

  async searchByGrandTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sGrandTotalMin = this.searchFilters.sGrandTotalMin;
    this.filters.sGrandTotalMax = this.searchFilters.sGrandTotalMax;
    await this.fetchData();
  }

  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin;
    this.filters.sTotalMax = this.searchFilters.sTotalMax;
    await this.fetchData();
  }

  async sortingByReseller() {
    this.sortBy(SortField.Customer);
  }

  async sortingByGrandTotal() {
    this.sortBy(SortField.GrandTotal);
  }

  async sortingByTotal() {
    this.sortBy(SortField.Total);
  }

  async sortingByDate() {
    this.sortBy(SortField.Date);
  }

  //go to search page
  gotoSearch(searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  }

  async searchChange(searchData) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchAll = searchData;
    await this.fetchData();
  }

  async checkStatusList(status: number) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.sStatus.findIndex(s => s === status);
    if (i === -1) {
      this.filters.sStatus.push(status);
    } else {
      this.filters.sStatus.splice(i, 1);
    }

    await this.fetchData();
  }

  async checkAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [
      QuoteStatus.Requested,
      QuoteStatus.Assigned,
      QuoteStatus.Created,
      QuoteStatus.Ordered
    ];
    await this.fetchData();
  }

  async resetStatusList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sStatus = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  //filter by status open/closed/purchased
  async filterByqOpenStatus(status: []) {
    this.hasChangedStatusFilters = true;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.pageNumber = 1;
    if (
      $("ul#filterList").find("input[type=checkbox].qStatus").length ==
      $("ul#filterList").find("input[type=checkbox].qStatus:not(:checked)")
        .length
    ) {
      this.filters.qOpenStatus = [60];
      //await this.fetchData();
    } else {
      for (var tmp of status) {
        if (this.filters.qOpenStatus.includes(tmp)) {
          this.filters.qOpenStatus.splice(
            this.filters.qOpenStatus.indexOf(tmp),
            1
          );
        } else {
          this.filters.qOpenStatus.push(tmp);
        }
      }
    }
    this.selectedStatuses = true;
    // await this.fetchData();
  }
  async checkAllqOpenStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.pageNumber = 1;
    this.filters.qOpenStatus = [0, 1, 2, 5, 6, 7, 8];
    this.selectedStatuses = true;
    await this.fetchData();
  }
  async uncheckAllqOpenStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.pageNumber = 1;
    this.filters.qOpenStatus = [];
    this.filters.filterStatusName = [];
    this.selectedStatuses = true;
    await this.fetchData();
  }
  async resetqOpenStatusList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.pageNumber = 1;
    this.filters.qOpenStatus =
      this.currentRole === "Reseller" ? [0, 1, 5, 6, 8] : [0, 1, 5, 6, 8];
    this.selectedStatuses = true;
    this.filters.filterStatusName = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchById(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchId = this.searchFilters.searchId = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchBySubTotal(allowFetchData = true) {
		this.pageNumber = 1;
		this.selectedID = [];
		this.filters.sTotalMin = this.searchFilters.sTotalMin = undefined;
		this.filters.sTotalMax = this.searchFilters.sTotalMax = undefined;
		this.filters.filterTotal = undefined;
		this.selectedAll = false;
		this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
	}

  async resetSearchByTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sGrandTotalMin = this.searchFilters.sGrandTotalMin = undefined;
    this.filters.sGrandTotalMax = this.searchFilters.sGrandTotalMax = undefined;
    this.filters.filterGrandTotal = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchName = this.searchFilters.searchName = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByAccountName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = this.searchFilters.sDate = undefined;
      this.filters.eDate = this.searchFilters.eDate = undefined;
      this.filters.filterQuoteDate = undefined;
      this.quoteStartDate = undefined;
      this.quotedueEndDate = undefined;
      if(allowFetchData) {
        await this.fetchData();
      }
    }
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchId == "") {
      return;
    } else {
      this.filters.searchId = this.searchFilters.searchId;
      await this.fetchData();
    }
  }

  async resetSearchByHPId(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.HpQuote = this.searchFilters.HpQuote = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByHPId() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.HpQuote == "") {
      return;
    } else {
      this.filters.HpQuote = this.searchFilters.HpQuote;
      await this.fetchData();
    }
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchName == "") {
      return;
    } else {
      this.filters.searchName = this.searchFilters.searchName;
      await this.fetchData();
    }
  }

  async searchByAccountName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchAccountName == "") {
      return;
    } else {
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }

  // async searchByQuoteCreator() {
  //   this.pageNumber = 1;
  //   this.selectedID = [];
  //   this.selectedAll = false;
  //   this.excludedIDs = [];
  //   if (this.searchFilters.quoteCreator == "") {
  //     return;
  //   } else {
  //     this.filters.quoteCreator = this.searchFilters.quoteCreator;
  //     await this.fetchData();
  //   }
  // }

  async resetSearchByQuoteCreator(allowFetchData = true) {
    this.resetSelected();
    this.pageNumber = 1;
    // this.filters.quoteCreator = this.searchFilters.quoteCreator = "";
    this.filters.varUserIds = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async checkAllQuoteCreator() {
    this.filters.varUserIds = this.filteredVarUsers.map(item => item.USERID);
    if(!this.filters.varUserIds.length) {
      return;
    }
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData();
  }

  async filterByReseller() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  // async checkResellerList() {
  //   this.pageNumber = 1;
  //   this.selectedID = [];

  //   const index = this.filters.resellerCheckList.indexOf(value);
  //   if (index < 0) {
  //     this.filters.resellerCheckList.push(value);
  //   } else {
  //     this.filters.resellerCheckList.splice(index, 1);
  //   }

  //   await this.fetchData()
  // }

  async checkAllResellerList() {
    var comInstance = this;
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    // this.filters.resellerCheckList = [ResellerCheckList.TestVAR, ResellerCheckList.TestCustomer, ResellerCheckList.Milestone]
    this.filters.resellerCheckList = [];
    this.resellers.forEach(function(val: any) {
      comInstance.filters.resellerCheckList.push(val.ACCOUNTID);
    });
    await this.fetchData();
  }

  async resetResellerList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.resellerCheckList = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else {
      if(this.searchFilters.sDate == "") {
        this.filters.sDate = this.searchFilters.sDate = undefined;
        this.quoteStartDate = undefined;
      } else if(this.searchFilters.eDate == "") {
        this.filters.eDate = this.searchFilters.eDate = undefined;
        this.quotedueEndDate = undefined;
      }
      this.filters.sDate = this.searchFilters.sDate;
      this.filters.eDate = this.searchFilters.eDate;
      await this.fetchData();
    }
  }

  // async removeSelected() {
  //   this.loading = true
  //   this.confirmRemoveModalVisible = false
  //   const successCount = await quoteRepo.removeByIds(this.selectedID, this.selectedAll, this.excludedIDs)
  //   //await this.fetchData()
  //   if (successCount > 0) {
  //     await this.fetchData();
  //     $(".actionsBtn").addClass('gray').removeClass('teal');
  //     notifier.success(`${successCount} Quote(s) Deleted Successfully`)
  //     this.selectedID = [];
  //     this.selectedAll = false;
  //     this.excludedIDs = [];
  //   }
  //   this.loading = false
  // }

  async htmlDownLoadFn() {
    const html = await this.selectedQuoteHtml("html");

    return () => {
      if (!html) return;
      var fileNameExport =
        "QuoteList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async selectedQuoteHtml(from: string): Promise<string | false> {
    try {
      const quoteList = await quoteRepo.exportQuoteBySelect(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
      if (from == "print") {
        quoteList["type"] = "print";
      }
      return template(quoteList);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async print() {
    await this.exportHeaders();
    this.loading = true;
    const html = await this.selectedQuoteHtml("print");
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }

  showUpdateStatusModal() {
    this.updateStatusModalVisible = true;
  }

  isManyLongHeaders() {
    let needFixHeaderWidth = false;
    let totalLongName = 0;
    this.selectedheaders.map(header => {
      const headerName = this.getListHeader(header);
      if (headerName.length > 10) {
        totalLongName = totalLongName + 1;
      }
    });
    if (totalLongName > 3) {
      needFixHeaderWidth = true;
    }
    return needFixHeaderWidth;
  }

  async updateStatus(payl: { status: "open" | "close" | "purchased" | "requested" | "assigned" | "purchasePending" }) {
    this.updateStatusModalVisible = false;
    const successCount = this.getSelectedTotal(this.quotesData.TOTAL);
    var comInstance = this;
    var statusIndex = "";
    if (payl.status == "open") {
      statusIndex = "1";
    } else if (payl.status == "close") {
      statusIndex = "2";
    } else if (payl.status == "purchased") {
      statusIndex = "5";
    } else if (payl.status == "requested") {
      statusIndex = "6";
    } else if (payl.status == "assigned") {
      statusIndex = "7";
    } else if (payl.status == "purchasePending") {
      statusIndex = "8";
    }

    if (statusIndex !== "" && this.selectedID.length) {
      this.loading = true;
      try {
        await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Quotes",
          FunctionName: "UpdateQuoteStatus",
          selectedIDs: this.selectedID.join(","),
          qopen: statusIndex,
          selectedAll: this.selectedAll,
          excludedIDs: this.excludedIDs
        });

        this.selectedID = [];
        this.selectedAll = false;
        this.excludedIDs = [];

        await this.fetchData();
        $(".actionsBtn")
          .addClass("gray")
          .removeClass("teal");
        notifier.success(
          `${successCount} Quote(s) status updated Successfully`
        );
      } catch (err: any) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }

  async changeStatus(quoteId: string, status: QuoteStatusValue) {
    //const statusIndex = status === QuoteStatusValue.Open ? '1' : status === QuoteStatusValue.Close ? '2' : status === QuoteStatusValue.Purchased ? '5' : ''

    try {
      this.loading = true;
      await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "UpdateQuoteStatus",
        selectedIDs: quoteId,
        qopen: status
      });
      this.selectedID = [];
      this.selectedAll = false;
      this.excludedIDs = [];

      await this.fetchData();
      notifier.success(`Quote ${quoteId}'s Status Updated Successfully`);
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  // showConfirmRemoveItemModal() {
  //     this.confirmRemoveModalVisible = true
  // }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await quoteRepo.exportListsPDFFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await quoteRepo.exportListsCSVFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;
    await this.exportHeaders();
    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }
  async exportHeaders() {
    var widthSizes = {
      "col-1": 1,
      "col-1-5": 1.5,
      "col-2": 2,
      "col-2-5": 2.5,
      "col-3": 3,
      "col-3-5": 3.5,
      "col-4": 4,
      "col-5": 5,
      "col-6": 6
    };
    this.exportHeadersArr = [];

    if (this.selectedheaders.length >= 11) {
      for (var val of this.selectedheaders) {
        const headerItem = this.pageHeaders.find((item) => item.key == val) || { key: '', value: '' };
        const tempObj = {
          header: val,
          column: headerItem.value,
          widthPercent: (100/this.selectedheaders.length) + "%"
        };
        this.exportHeadersArr.push(tempObj);
      }
    } else {
      for (var val of this.selectedheaders) {
        var cls = this.getColumnWidth(val).replace(" tar", "");
        cls = cls.replace(" lastSearchBox", "");
        var width = (widthSizes[cls] * 100) / 12 + "%";
        const headerItem = this.pageHeaders.find((item) => item.key == val) || { key: '', value: '' };
        var tempObj = {
          header: val,
          column: headerItem.value,
          widthPercent: width
        };
        this.exportHeadersArr.push(tempObj);
      }
    }
  }
  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["Quotes"] ){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["Quotes"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }
    if (this.pageNumber <= this.quotesData.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  selectAll(selectedAll = true) {
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.items) {
        if (!this.excludedIDs.includes(val.QUOTEID)) {
          this.selectedID.push(val.QUOTEID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  checkExcludedIDs(items, key) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.quotesData.TOTAL) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }
  async toggleCustomView(currStatus) {
    this.viewType = currStatus;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.selectedID = [];
    this.selectedView = true;
    this.pageNumber = 1;
    if (typeof this.filters["viewFrom"] != "undefined") {
      this.filters["viewFrom"] = "";
      if (
        typeof this.filters["gTagIDs"] != "undefined" &&
        this.filters["gTagIDs"]
      ) {
        this.filters["gTagIDs"] = [];
        this.filters.AccountTagID = [];
      }
      if (
        typeof this.filters["groupIDs"] != "undefined" &&
        this.filters["groupIDs"]
      ) {
        this.filters["groupIDs"] = [];
        this.filters.AccountTagID = [];
      }
    }

    await this.fetchData();
    this.accsLoad();
  }

  resetSelected() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
  }

  // Source filter

  async checkSourceList(status: string, name: string) {
    this.hasChangedSourceFilters = true;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.sSource.findIndex(s => s === status);
    if(this.filters.sSource != undefined && this.filters.sourceName != undefined) {
      const i = this.filters.sSource.findIndex((s) => s === status);
      const sourceIndex = this.filters.sourceName.findIndex((sourceValue) => sourceValue === name);
      if (i === -1) {
        this.filters.sSource.push(status);
      } else {
        this.filters.sSource.splice(i, 1);
      }
      if (sourceIndex === -1) {
        this.filters.sourceName.push(name);
      } else {
        this.filters.sourceName.splice(sourceIndex, 1);
      }
    }
    // await this.fetchData();
  }

  // Business Line filter
  async checkBusinessLineList(bLine: string, name: string) {
    this.hasChangedBusinessLineFilters = true;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.businessLineId.findIndex(s => s === bLine);
    if(this.filters.businessLineId != undefined && this.filters.businessLineName != undefined) {
      const i = this.filters.businessLineId.findIndex((s) => s === bLine);
      const bLineIndex = this.filters.businessLineName.findIndex((bLineValue) => bLineValue === name);
      if (i === -1) {
        this.filters.businessLineId.push(bLine);
      } else {
        this.filters.businessLineId.splice(i, 1);
      }
      if (bLineIndex === -1) {
        this.filters.businessLineName.push(name);
      } else {
        this.filters.businessLineName.splice(bLineIndex, 1);
      }
    }
  }

  async checkAllSourceList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sSource = Object.keys(this.quotesData.sourcesObj);
    this.filters.sourceName = Object.values(this.quotesData.sourcesObj);
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  // System filter
  hasChangedSystemFilters = false;
  async checkSystemList(name) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.filters.SSURL != undefined) {
      const systemIndex = this.filters.SSURL.findIndex(
          systemValue => systemValue === name
      );
      if (systemIndex === -1) {
        this.filters.SSURL.push(name);
      } else {
        this.filters.SSURL.splice(systemIndex, 1);
      }
    }
    this.hasChangedSystemFilters = true;
    this.filters.viewFrom = '';
  }

  async checkAllSystemList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.SSURL = (this.quotesData.SYSTEMS || []).map((system) => { return system.SSID; });
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async uncheckSystemList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.SSURL = [];
    await this.fetchData();
  }

  async resetSystemList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.SSURL = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchSSURL = '';
    this.checkAllSystemList(allowFetchData);
  }

  async checkAllBusinessLineList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.businessLineId = Object.keys(this.quotesData.businessLinesObj);
    this.filters.businessLineName = Object.values(this.quotesData.businessLinesObj);
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  
  async uncheckAllSourceList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sSource = [];
    this.filters.sourceName = [];
    await this.fetchData();
  }

  async uncheckBusinessLineList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.businessLineId = [];
    this.filters.businessLineName = [];
    await this.fetchData();
  }

  async resetSourceList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sSource = [];
    this.filters.sourceName = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllSourceList(allowFetchData);
  }

  async resetBusinessLineList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.businessLineId = [];
    this.filters.businessLineName = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllBusinessLineList(allowFetchData);
  }

  //Filter by department name
  async searchByDeptName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.deptName == "") {
      return;
    } else {
      this.filters.deptName = this.searchFilters.deptName;
      await this.fetchData();
    }
  }

  //Filter by department name
  async resetSearchByDeptName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.deptName = this.searchFilters.deptName = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  //filter by Tags
  async filterByTag(tagName = '', groupName = '') {
    this.hasChangedAccountFilters = true;
    // if(tagName != "") {
    //   const tagIndex = this.filters.accountTagName.findIndex(tagValue => tagValue === tagName);
    //   if (tagIndex === -1) {
    //     this.filters.accountTagName.push(tagName);
    //   } else {
    //     this.filters.accountTagName.splice(tagIndex, 1);
    //   }
    // }

    // if(groupName != '') {
    //   const groupIndex = this.filters.groupName.findIndex(groupValue => groupValue === groupName);
    //   if (groupIndex === -1) {
    //     this.filters.groupName.push(groupName);
    //   } else {
    //     this.filters.groupName.splice(groupIndex, 1);
    //   }
    // }
    this.tagIDs = [];
    if (this.filters.AccountTagID.length || this.filters.gTagIDs.length || this.filters.groupIDs.length) {
      this.selectedView = true;
      this.CurrentView = this.getCurrentViewTitle();
      // if(!(this.filters.groupIDs && this.filters.groupIDs.length)) {
        // this.viewType = "1"; //just set a number
      // }
      // for (let i in this.filters.AccountTagID) {
      //   let item: any = this.filters.AccountTagID[i];
      //   if (item.ISGLOBALTAG) {
      //     this.gTagIDs.push(item.GTAGID);
      //   } else {
      //     this.tagIDs.push(item.ACCOUNTTAGID);
      //     this.filters.AccountTagID.push(item.ACCOUNTTAGID);
      //   }
      // }
    } else {
      // reset
      this.selectedView = false;
      if(!this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
        this.CurrentView = "My Quotes";
        this.viewType = "CurrentUsers";
			}

    }
    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    var $this = this;
    this.filters.AccountTagID = [];
    // this.filters.accountTagName = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
        // $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
        // $this.filters.accountTagName.push(val.TAGNAME);
        if (typeof val.ISGLOBALTAG == "undefined") {
          if (typeof $this.filters.AccountTagID != "undefined" ) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
          }
        } else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
        }
      }
    });
    this.filterByTag();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    this.resetSelected();
    var $this = this;
    this.filters.groupIDs = [];
    // this.filters.groupName = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
        $this.filters.groupIDs.push(val.ACCOUNTTAGID);
        // $this.filters.groupName.push(val.TAGNAME);
      }
    });
    this.filterByTag();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.resetSelected();
    this.filters.AccountTagID = [];
    // this.filters.accountTagName = [];
    // this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    // this.filters.groupName = [];
    this.pageNumber = 1;
    this.filterByTag();
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetGroupTag(allowFetchData = true) {
    this.resetSelected();
    // this.filters.AccountTagID = [];
    // this.filters.accountTagName = [];
    this.filters.groupIDs = [];
    // this.filters.groupName = [];
    this.pageNumber = 1;
    this.filterByTag();
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  toggleActive(div) {
    this.isActive = div;
  }
  filterTagsInBox() {
    let options = this.quotesData["accountTags"];

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName: string) {
    this.hasChangedAccountFilters = true;
    if(accountsName != undefined) {
      const accountsIndex = this.filters.selectedAccountsName.findIndex(accountsValue => accountsValue === accountsName);
      if (accountsIndex === -1) {
        this.filters.selectedAccountsName.push(accountsName);
      } else {
        this.filters.selectedAccountsName.splice(accountsIndex, 1);
      }
    }
    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.filters.selectedAccountsName = this.varAccsList.map((val) => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.filters.selectedAccountsName = [];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async accsLoad() {
    // if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          view: this.viewType,
          isQuote: 1,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
          if (this.searchAccTxt != "") {
						await this.filterAccsInBox();
					}
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    // }
  }
  async addHeader(c) {
    const addedCol = this.selectedheaders.includes(c);
    colsResizeableReset();
    var sortedHeaders: string[] = [];
    for (var ts in this.headerOrder) {
      var valIdx: string = this.headerOrder[ts];
      const i = this.selectedheaders.includes(valIdx);
      if (i) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedheaders = sortedHeaders;

    // data to save selected headers
    const saveHeaders: string[] = [];
    for (const fieldId of this.selectedheaders) {
      saveHeaders.push(fieldId);

      // select all custom fields have same name
      const fieldName = this.pageHeaders[fieldId];
      if (fieldId.indexOf("customData") != -1 && fieldName) {
        const relatedFields = this.listCustomFields.filter(
          t =>
            t.CUSTOMFIELDNAME.toLowerCase() == fieldName.toLowerCase() &&
            !saveHeaders.includes(`customData${t.CUSTOMFIELDID}`)
        );
        for (const t of relatedFields) {
          saveHeaders.push(`customData${t.CUSTOMFIELDID}`);
        }
      }
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: saveHeaders.join(","),
      from: "quote"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
  
		if(!this.selectedheaders.includes("QOPEN")) {
        this.filters.qOpenStatus = this.currentRole === "Reseller" ? [0, 1, 5, 6, 8] : [0, 1, 5, 6, 8];
        this.selectedStatuses = true;
        this.filters.filterStatusName = [];
			// this.resetqOpenStatusList();
		}
		if(!this.selectedheaders.includes("QUOTEID")) {
      this.filters.searchId = this.searchFilters.searchId = "";
			// this.resetSearchById();
		}
		if(!this.selectedheaders.includes("QDESCRIPTION")) {
      this.filters.searchName = this.searchFilters.searchName = "";
			// this.resetSearchByName();
		}
		if(!this.selectedheaders.includes("ACCOUNTNAME")) {
      this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
			// this.resetSearchByAccountName();
		}
		if(!this.selectedheaders.includes("TOTALFORMATTED")) {
      this.filters.sTotalMin = this.searchFilters.sTotalMin = undefined;
      this.filters.sTotalMax = this.searchFilters.sTotalMax = undefined;
      this.filters.filterTotal = undefined;
			// this.resetSearchBySubTotal();
		}
		if(!this.selectedheaders.includes("GRANDTOTALFORMATTED")) {
      this.filters.sGrandTotalMin = this.searchFilters.sGrandTotalMin = undefined;
      this.filters.sGrandTotalMax = this.searchFilters.sGrandTotalMax = undefined;
      this.filters.filterGrandTotal = undefined;
			// this.resetSearchByTotal();
		}
		if(!this.selectedheaders.includes("NEWDATEFORMATTED")) {
      this.filters.sDate = this.searchFilters.sDate = undefined;
      this.filters.eDate = this.searchFilters.eDate = undefined;
      this.filters.filterQuoteDate = undefined;
      this.quoteStartDate = undefined;
      this.quotedueEndDate = undefined;
			// this.resetSearchByDate();
		}

		if(!this.selectedheaders.includes("SOURCE")) {
      this.filters.sSource = Object.keys(this.quotesData.sourcesObj);
      this.filters.sourceName = Object.values(this.quotesData.sourcesObj);
			// this.resetSourceList();
		}

		if(!this.selectedheaders.includes("HPQuoteNumber")) {
      this.filters.HpQuote = this.searchFilters.HpQuote = "";
			// this.resetSearchByHPId();
		}

		if(!this.selectedheaders.includes("DEPARTMENTNAME")) {
			this.filters.deptName = this.searchFilters.deptName = "";
		}

    if(!this.selectedheaders.includes("QOPEN") || !this.selectedheaders.includes("QUOTEID") || !this.selectedheaders.includes("QDESCRIPTION") || !this.selectedheaders.includes("ACCOUNTNAME") || !this.selectedheaders.includes("TOTALFORMATTED") || !this.selectedheaders.includes("GRANDTOTALFORMATTED") || !this.selectedheaders.includes("NEWDATEFORMATTED") || !this.selectedheaders.includes("SOURCE") || !this.selectedheaders.includes("HPQuoteNumber")) {
      this.pageNumber = 1;
      this.selectedID = [];
      this.selectedAll = false;
      this.excludedIDs = [];
      // await this.fetchData();
    }

    if(addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      await this.fetchData();
    }

    // if (i === -1) {
    //   this.selectedheaders.push(c);
    // } else {
    //   this.selectedheaders.splice(i, 1);
    // }

    colsResizeable();
  }
  getColumnWidth(e, frm = 0) {
    var headerCls = "";
    switch (this.selectedheaders.length) {
      case 3:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-3";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-6";
            break;
          default:
            headerCls = "col-3 tar";
            break;
        }
        break;
      case 4:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-3";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-5";
            break;
          case "QOPEN":
            headerCls = "col-3";
            break;
          case "QDESCRIPTION":
            headerCls = "col-3";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-2";
            break;
          case "HPQuoteNumber":
            headerCls = "col-3";
            break;
          case "SOURCE":
            headerCls = "col-3";
            break;
          default:
            headerCls = "col-2 tar";
            break;
        }
        break;
      case 5:
        switch (e) {
          case "QUOTEID":
            if (this.$isHideReseller)  { 
              headerCls = "col-3"
            }
            else {
              headerCls = "col-2";
            }
            break;
          case "ACCOUNTNAME":
            headerCls = "col-4";
            break;
          case "QOPEN":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-2";
            }
            break;
          case "QDESCRIPTION":
            if (this.$isHideReseller)  { 
              headerCls = "col-4"
            }
            else {
              headerCls = "col-3";
            }
            break;
          case "NEWDATEFORMATTED":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-2";
            }
            break;
          case "HPQuoteNumber":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-2";
            }
            break;
          case "SOURCE":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-2";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2";
            break;
          case "GRANDTOTALFORMATTED":
            if (this.$isHideReseller)  { 
              headerCls = "col-3 tar"
            }
            else {
              headerCls = "col-2 tar";
            }
            break;
          default:
            headerCls = "col-2 tar";
            break;
        }
        break;
      case 6:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-1-5";
            if (!this.selectedheaders.includes("QDESCRIPTION") && this.selectedheaders.includes("TOTALFORMATTED")) {
              headerCls = "col-2";
            }
            break;
          case "ACCOUNTNAME":
            headerCls = "col-3";
            break;
          case "QOPEN":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            break;
          case "QDESCRIPTION":
            headerCls = "col-3";
            break;
          case "NEWDATEFORMATTED":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            break;
          case "HPQuoteNumber":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            break;
          case "SOURCE":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 7:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-1-5";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-3";
            break;
          case "QOPEN":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "QDESCRIPTION":
            headerCls = "col-2-5";
            break;
          case "NEWDATEFORMATTED":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "HPQuoteNumber":
            if (this.selectedheaders.includes("QDESCRIPTION") && !this.selectedheaders.includes("SOURCE") && !this.selectedheaders.includes("QOPEN")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "SOURCE":
            if (this.selectedheaders.includes("QDESCRIPTION") && !this.selectedheaders.includes("QOPEN")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1-5";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 8:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-1-5";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2";
            break;
          case "QOPEN":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "QDESCRIPTION":
            headerCls = "col-2";
            break;
          case "NEWDATEFORMATTED":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "HPQuoteNumber":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-1";
            }
            break;
          case "SOURCE":
            if (this.selectedheaders.includes("QDESCRIPTION") && !this.selectedheaders.includes("QOPEN")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1-5";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 9:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-1-5";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2";
            break;
          case "QOPEN":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "QDESCRIPTION":
            headerCls = "col-2";
            break;
          case "NEWDATEFORMATTED":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "HPQuoteNumber":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "SOURCE":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 10:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-1";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-1-5";
            break;
          case "QOPEN":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "QDESCRIPTION":
            headerCls = "col-2";
            break;
          case "TOTALFORMATTED":
            headerCls = "col-1 tar";
            break;
          case "NEWDATEFORMATTED":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "HPQuoteNumber":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "SOURCE":
            if (this.selectedheaders.includes("QDESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      default:
        switch (e) {
          case "QUOTEID":
            headerCls = "col-1-5";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2-5";
            break;
          case "QOPEN":
            headerCls = "col-1";
            break;
          case "QDESCRIPTION":
            headerCls = "col-2-5";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "HPQuoteNumber":
            headerCls = "col-1";
            break;
          case "SOURCE":
            headerCls = "col-1";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
    }

    if(e.indexOf("customData") != -1) {
      headerCls = "col-1";
    }
    if (
      (this.selectedheaders[this.selectedheaders.length - 1] == e ||
        this.selectedheaders[this.selectedheaders.length - 2] == e)
    ) {
      headerCls += " lastSearchBox";
    }
    return headerCls;
  }

  sorting(c) {
    switch (c) {
      case "QOPEN":
        this.sortingByqOpenStatus();
        break;
      case "QUOTEID":
        this.sortingById();
        break;
      case "ACCOUNTNAME":
        this.sortingByReseller();
        break;
      case "QDESCRIPTION":
        this.sortingByName();
        break;
      case "NEWDATEFORMATTED":
        this.sortingByDate();
        break;
      case "GRANDTOTALFORMATTED":
        this.sortingByGrandTotal();
        break;
      case "SOURCE":
        this.sortBy(SortField.Source);
        break;
      case "TOTALFORMATTED":
        this.sortBy(SortField.Total);
        break;
      case "DEPARTMENTNAME":
        this.sortBy(SortField.DepartmentName);
        break;
      case "SSURL":
        this.sortBy(SortField.system);
        break;
      case "BUSINESSLINENAME":
        this.sortBy(SortField.businessLineName);
        break;
      case "QUOTECREATOR":
        this.sortBy(SortField.quoteCreator);
        break;
    }
  }
  getCurrentViewTitle() {
    if (this.filters.AccountTagID.length || this.filters.gTagIDs.length || this.filters.groupIDs.length) {
      var fulltitle:any = [];
      var $this = this;
      this.accountTags.forEach(function (val: any) {
      if (!isNaN(val.ACCOUNTTAGID)) {
        if (typeof val.ISGLOBALTAG == 'undefined' && typeof val.ISGROUP == 'undefined' && $this.filters.AccountTagID && $this.filters.AccountTagID.includes(val.ACCOUNTTAGID)) {
          fulltitle.push(val.TAGNAME);
        }
        if (typeof val.ISGLOBALTAG != 'undefined' && $this.filters.gTagIDs && $this.filters.gTagIDs.includes(val.ACCOUNTTAGID)) {
          fulltitle.push(val.TAGNAME);
        }
        if (typeof val.ISGROUP != 'undefined' && $this.filters.groupIDs && $this.filters.groupIDs.includes(val.ACCOUNTTAGID)) {
          fulltitle.push(val.TAGNAME);
        }
      }
    });
    return fulltitle.join(", ");

    }

    return "";
  }
  async selectView(item, e) {
    if (isNaN(item.ACCOUNTTAGID)) {
      // selected my customers / company customers
      this.tagIDs = [];
      this.filters.gTagIDs = [];
      this.filters.groupIDs = [];

      this.filters.AccountTagID = []; // reset
      this.selectedTags = []; // reset
      this.CurrentView = item.TAGNAME;
      this.viewType = item.ACCOUNTTAGID;
      // if(typeof item.ISGLOBALTAG != "undefined" && item.ISGLOBALTAG) {//selected a global tag
      //   this.dashView = item.GTAGID;
      //   this.selectedGlobalTag = true;
      // }else {
      //   this.dashView = item.ACCOUNTTAGID;
      //   this.selectedGlobalTag = false;
      // }
      this.selectedView = true;
      await this.fetchData();
      await this.accsLoad();
    } else {
      e.stopPropagation();
    }
  }

  validFilters(query: object, position: object) {
    var arr: any[] = [];
    query["filterStatusName"] = query["filterStatusName"] ? query["filterStatusName"] : "None";
    query["sourceName"] = query["sourceName"] ? query["sourceName"] : "None";
    const queryFilters = Object.entries(query).reduce((a, [k, v]) => {
      if(k == "qOpenStatus" && $.inArray(-1, v) != -1){
        v.splice($.inArray(-1, v), 1)
      }
      if (k == "qOpenStatus" || k == "filterStatusName") {
        return v.length >= 6 ? a : { ...a, [k]: v };
      }
      if ((k == "sSource" || k == "sourceName") && this.quotesData.sourcesObj) {
        return (v.length == Object.keys(this.quotesData.sourcesObj).length) ? a : { ...a, [k]: v };
      }
      if ((k == "businessLineId" || k == "businessLineName") && this.quotesData.businessLinesObj) {
        return (v.length == Object.keys(this.quotesData.businessLinesObj).length) ? a : { ...a, [k]: v };
      }
      if (
          (k == "SSURL" ) &&
          typeof this.quotesData.SYSTEMS != "undefined"
      ) {
        return v.length == this.quotesData.SYSTEMS.length
            ? a
            : { ...a, [k]: v };
      }

      return v == null || v === "" || k == "unassigned" ? a : { ...a, [k]: v };
    }, {});

    const _systems = this.quotesData.SYSTEMS || [];
    $.each(queryFilters, function(key,valueObj){
      let tmpValue = valueObj;
      if(key == 'SSURL') {
        tmpValue = valueObj.map((value) => {
          const tmpSystem = _systems.find((system) => `${system.SSID}` == value);
          if (tmpSystem) {
            return tmpSystem.SSURL;
          } else {
            return `#${value}`;
          }
        });
      }
      if(position[key] != undefined) {
        let tempStruct: any = {};
        tempStruct[key] = tmpValue;
        tempStruct["position"] = position[key];
        arr.push(tempStruct);
      }
    });
    var sortable = Object.entries(arr).sort(function(a, b) {
      return a[1].position - b[1].position;
    });

    var validFilters: any[] = [];
    for(var i in sortable){
      let val = sortable[i]
      let lastItem = val[sortable[i].length - 1];
      let key = Object.keys(lastItem)[0];
      var value = Object.values(lastItem)[0];
      if(Array.isArray(value)) {
        value = value.join(" ,");
      }
      let tempFilterStruct: any = {};
      tempFilterStruct[key] = value;
      validFilters.push(tempFilterStruct);
    }
    return validFilters;
  }

  async clearFilter() {
    this.resetqOpenStatusList(false);
    this.resetSearchById(false);
    this.resetSearchByName(false);
    this.resetSearchByAccountName(false);
    this.resetAcc(false);
    this.resetSearchBySubTotal(false);
    this.resetSearchByTotal(false);
    this.resetSearchByDate(false);
    this.resetSearchByHPId(false);
    this.resetSearchByDeptName(false);
    this.resetSourceList(false);
    this.resetTag(false);
    this.filterModalVisible = false;
    await this.fetchData();
  }

  quotesCheckAllChange() {
		this.quotesCheckAll = !this.quotesCheckAll;
		if (!this.quotesCheckAll) {
			this.selectInPage(false);
		} else {
			this.selectInPage(true);
		}
    this.checkExcludedIDs(this.items, 'QUOTEID');
	}

  selectInPage(checked = true) {
    const quoteIds = this.items.map(item => item.QUOTEID);
    if(checked) {
      // check all
      this.selectedID = [...this.selectedID, ...quoteIds];
      this.selectedID = [...new Set(this.selectedID)];
      this.excludedIDs = this.excludedIDs.filter(id => !quoteIds.includes(id));
    }else {
      // uncheck all
      this.selectedID = this.selectedID.filter(id => !quoteIds.includes(id));
    }
	}


  removeColumn(header, x) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerOrder) {
      const valIdx: string = this.headerOrder[ts];
      const i = this.selectedheaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedheaders = sortedHeaders;
    this.addHeader(x);
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'QUOTEID':
        searchKey = this.filters.searchId;
        break;
      case 'QDESCRIPTION':
        searchKey =  this.filters.searchName;
        break;
      case 'ACCOUNTNAME':
        {
          const arr: string[] = [];
          if(this.filters.searchAccountName) {
             arr.push(`Customer: ${this.filters.searchAccountName}`);
          }
          if (this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
            const selectedTags = this.accountTags
              .filter(
                t =>
                  !(t.ISGLOBALTAG || false) &&
                  !(t.ISGROUP || false) &&
                  t.ACCOUNTTAGID > 0 &&
                  this.filters.AccountTagID.includes(t.ACCOUNTTAGID)
              )
              .map(t => t.TAGNAME);
            const selectedGtags = this.accountTags
              .filter(
                t =>
                  (t.ISGLOBALTAG || false) &&
                  this.filters.gTagIDs.includes(t.GTAGID)
              )
              .map(t => t.TAGNAME);
            const selectedTagNames = [...selectedTags, ...selectedGtags];
            arr.push(`Customer Tags: ${selectedTagNames.join(", ")}`);
          }
          if(this.filters.selectedAccs.length) {
             arr.push(`Customer Accounts: ${this.filters.selectedAccountsName}`);
          }
          if(this.filters.groupIDs.length) {
            const selectedGroups = this.accountTags
              .filter(
                t =>
                  (t.ISGROUP || false) &&
                  this.filters.groupIDs.includes(t.ACCOUNTTAGID)
              )
              .map(t => t.TAGNAME);
            arr.push(`Customer Groups: ${selectedGroups.join(", ")}`);
          }
          searchKey = arr.join('; ');
          break;
        }
      case 'NEWDATEFORMATTED':
        searchKey = getDateRangeFilter(this.filters.sDate, this.filters.eDate);
        break;
      case 'DEPARTMENTNAME':
        searchKey = this.filters.deptName;
        break;
      case 'HPQuoteNumber':
        searchKey = this.filters.HpQuote;
        break;
      case 'TOTALFORMATTED':
        filter = this.getRangeFilter(this.filters.sTotalMin, this.filters.sTotalMax);
        searchKey = '';
        break;
      case 'GRANDTOTALFORMATTED':
        filter = this.getRangeFilter(this.filters.sGrandTotalMin, this.filters.sGrandTotalMax);
        searchKey = '';
        break;
      case 'QOPEN':
        var statusFilters = this.currentRole === "Reseller" ? [0, 1, 5, 6, 8] : [0, 1, 5, 6, 8];
        if (!this.filters.filterStatusName) {
          filter = "None";
        } else if (statusFilters.length == this.filters.qOpenStatus.length && statusFilters.every(value => this.filters.qOpenStatus.includes(value))) {
          filter = "";
        } 
        else filter = this.filters.filterStatusName.join(', ');
        searchKey = '';
        break;
      case "SOURCE":
        if (this.filters.sourceName && !this.filters.sourceName.length)
          filter = "None";
        else if (this.filters.sourceName && Object.keys(this.quotesData.sourcesObj).length != this.filters.sourceName.length) {
          filter = this.filters.sourceName.join(", ");
        }
        searchKey = '';
        break;
      case "BUSINESSLINENAME":
        if (this.filters.businessLineName && !this.filters.businessLineName.length)
          filter = "None";
        else if (this.filters.businessLineName && Object.keys(this.quotesData.businessLinesObj).length != this.filters.businessLineName.length) {
          filter = this.filters.businessLineName.join(", ");
        }
        searchKey = '';
        break;
      case "QUOTECREATOR":
        if (this.filters.varUserIds.length) {
          searchKey = this.varUsers
            .filter(t => this.filters.varUserIds.includes(t.USERID))
            .map(t => t.UFULLNAME)
            .join(", ");
        }
        break;
      case "SSURL":
        if ((this.quotesData.SYSTEMS || []).length == this.filters.SSURL.length) {
          searchKey = '';
        } else {
          const selected = (this.quotesData.SYSTEMS || [])
              .filter(value=> (this.filters.SSURL || []).includes(value.SSID))
              .map(t => t.SSURL);
          searchKey = selected.join(", ") || 'None';
        }
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join('; ');
  }

  async resetColumn(header) {
    this.loading = true;
    switch (header) {
      case 'QUOTEID':
        this.resetSearchById();
        break;
      case 'QDESCRIPTION':
        this.resetSearchByName();
        break;
      case 'ACCOUNTNAME':
        this.resetSearchByAccountName(false);
        this.resetAcc(false);
        this.resetTag(false);
        this.resetGroupTag(false);
        await this.fetchData();
        break;
      case 'NEWDATEFORMATTED':
        this.resetSearchByDate()
        break;
      case 'DEPARTMENTNAME':
        this.resetSearchByDeptName();
        break;
      case 'HPQuoteNumber':
        this.resetSearchByHPId();
        break;
      case 'TOTALFORMATTED':
        this.resetSearchBySubTotal();
        break;
      case 'GRANDTOTALFORMATTED':
        this.resetSearchByTotal();
        break;
      case 'QOPEN':
        this.resetqOpenStatusList();
        break;
      case 'SOURCE':
        this.resetSourceList();
        break;
      case 'BUSINESSLINENAME':
        this.resetBusinessLineList();
        break;
      case 'QUOTECREATOR':
        this.resetSearchByQuoteCreator();
        break;
      case "SSURL":
        this.resetSystemList();
        break;
      default:
        // nothing
        break;
    }
  }

  // testListv2() {
  //   this.listv2 = 1;
  //   colsResizeable({ms: 500});
  // }

  modalProductCatalogData = {
    show: false,
  };
  
  getListHeader(key: string) {
    let ret = "";
    if (key.indexOf("customData") != -1) {
      const fieldId = key.replace("customData", "");
      const inList = this.listCustomFields.find(
          item => item.CUSTOMFIELDID == fieldId
      );
      if (inList) {
        ret = inList.CUSTOMFIELDNAME || "";
      }
    } else {
      if(this.currentRole != 'Customer') {
        ret = (this.pageHeaders.find((item) => item.key == key) || { key: '', value: ''}).value;
      }
      else{
        ret = (this.pageCustomerHeaders.find((item) => item.key == key) || { key: '', value: ''}).value;
      }
    }
    return ret;
  }

  hasCustomData() {
    const inList = this.selectedheaders.find(key => key.indexOf("customData") != -1);
    if(inList) {
      return true;
    }

    return false;
  }

  getCustomDataField(item, header) {
    // specify correct field by using listCustomFields
    const fieldName = (this.pageHeaders.find(headerItem => headerItem.key == header) || {key: '', value: ''}).value;
    const relatedField = this.listCustomFields.find(
        t =>
            t.CUSTOMFIELDNAME.toLowerCase() == fieldName.toLowerCase() &&
            (t.AID || 0) == (item.AID || 0)
    );
    if (relatedField) {
      return `CUSTOMDATA${relatedField.CUSTOMFIELDID}`;
    }

    return `CUSTOMDATA${header.replace("customData", "")}`;
  }

  handleItemClick(url) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    window.open(url, "_blank");
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  createOrder() {
    if(!this.selectedID.length) return;

    let isMultiEndUsers = false;
    if(this.selectedID.length == 1) {
      const inSelectedQuotes = this.selectedQuotes.find(item => item.QUOTEID == this.selectedID[0]);
      isMultiEndUsers = inSelectedQuotes && (inSelectedQuotes.ISMULTIENDUSERS || 0) ? true : false;
    }

    if(this.selectedID.length > 1 || isMultiEndUsers) {
      // convert to order with multi end users
      this.$router.push({
        name: "OrderCreate",
        params: {
          fromQuote: "0"
        },
        query: {
          quoteIds: this.selectedID.join(",")
        }
      });
    }else if(this.selectedID.length == 1) {
      // convert to order normally
      this.$router.push({
        name: "OrderCreate",
        params: {
          fromQuote: this.selectedID[0]
        }
      });
    }
  }

  updateSelectedQuotes() {
    if(!this.selectedID.length) {
      this.selectedQuotes = [];
      return;
    }
    this.selectedQuotes = this.selectedQuotes.filter(item => this.selectedID.includes(item.QUOTEID));
    // add more into selectedQuotes
    for(const quoteId of this.selectedID) {
      const inSelectedQuotes = this.selectedQuotes.find(item => item.QUOTEID == quoteId);
      if(!inSelectedQuotes) {
        const inCurrentQuotesList = this.items.find(item => item.QUOTEID == quoteId);
        if(inCurrentQuotesList) {
          this.selectedQuotes.push(inCurrentQuotesList);
        }
      }
    }
  }

  get allowCreateOrder() {
    if(!this.$allowedFunctions.includes(this.$userFunctions.Add_Order) || this.selectedAll || !this.selectedID.length) {
      return false;
    }
    // must same customers
    const accountIds = [...new Set(this.selectedQuotes.map(item => item.ACCOUNTID))];
    if(accountIds.length != 1) {
      return false;
    }

    return true;
  }

  searchByHeader() {
    if(this.currentRole != 'Customer') {
      /*
      const filteredHeader = Object.entries(this.pageHeaders).reduce((header, [key, value]) => {
        if (value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1) {
          header[key] = value;
        }
        return header;
      }, {});
      */
      let filteredHeader = this.pageHeaders.filter((item: any) => item.value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1);
      return filteredHeader;
    }
    else{
      /*
      const filteredCustomerHeader = Object.entries(this.pageCustomerHeaders).reduce((header, [key, value]) => {
        if (value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1) {
          header[key] = value;
        }
        return header;
      }, {});
      */
      let filteredCustomerHeader = this.pageCustomerHeaders.filter((item: any) => item.value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1);
      return filteredCustomerHeader;
    }
  }

  clickOutsideHeader() {
    this.searchHeader = "";
  }

  get filteredVarUsers() {
    let ret = this.varUsers || [];
    if (this.searchQuoteCreator) {
      ret = ret.filter(
        item =>
          item.UFULLNAME.toLowerCase().indexOf(
            this.searchQuoteCreator.toLowerCase()
          ) != -1
      );
    }

    return ret;
  }

  sortSelectedHeader() {
    const newSelectedHeaders: string[] = [];
    for (const item of this.pageHeaders) {
      if (!this.selectedheaders.includes(item.key)) continue;
      newSelectedHeaders.push(item.key);
    }
    this.selectedheaders = newSelectedHeaders;
  }

  async handleListChange(event) {
    this.sortSelectedHeader();
    const dataObj = {
      controller: "Users",
      FunctionName: "sortListColumns",
      page: 'Quotes',
      userid: sessionStorage.getItem("userId"),
      params: this.pageHeaders.map(item => item.key)
    };
    await axios.post(dataURL + "?ReturnType=JSON", dataObj);
  }
}
