import { render, staticRenderFns } from "./CustomerDetailsTabSettings.vue?vue&type=template&id=62c4f159&scoped=true"
import script from "./CustomerDetailsTabSettings.vue?vue&type=script&lang=tsx"
export * from "./CustomerDetailsTabSettings.vue?vue&type=script&lang=tsx"
import style0 from "./CustomerDetailsTabSettings.vue?vue&type=style&index=0&id=62c4f159&prod&scoped=true&lang=less"
import style1 from "./CustomerDetailsTabSettings.vue?vue&type=style&index=1&id=62c4f159&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c4f159",
  null
  
)

export default component.exports