

































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import AddFinancialBoardModal from "@/components/AddFinancialBoardModal.vue";
import { FinancialBoardsAPIRepo, BoardFilters, BoardSort, BoardSortField } from "@/repos/FinancesDashboardAPIRepo";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import Pagination from "@/components/Pagination.vue";
import BoardAccountComponent from "@/components/BoardAccount.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";
import { notifier } from "@/models/common";
import { getRangeFilter, checkTooltip } from "@/helpers/ApiHelper";

const financialBoardsRepo = new FinancialBoardsAPIRepo();

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    AddFinancialBoardModal,
    ConfirmRemoveItemModal,
    Pagination,
    BoardAccountComponent,
    ListHeaderActions,
    FilterCurrencyBox
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    }
  }
})

export default class Boards extends TSXComponent<void> {
  loading: boolean = false;
  pageNumber: number = 1;
  isSearch: number = 0;
  totalAll: number = 0;
  totalBoardItems: any = 0;
  details: any = {
    TOTALPAGES: 1,
    CURRENTPAGE: 1
  };
  addFinancialBoardModalVisible: boolean = false;
  boardList: any[] = [];
  searchFilters: BoardFilters = {
    boardName : "",
    rankMin: undefined,
    rankMax: undefined
  }
  filters: BoardFilters = {
    boardName : "",
    rankMin: undefined,
    rankMax: undefined
  }
  sort: BoardSort = {
    field: 0,
    direction: {
      [BoardSortField.boardName]: 1,
      [BoardSortField.ranking]: 1,
      [BoardSortField.yearRevenue]: 1,
      [BoardSortField.yearProfit]: 1,
      [BoardSortField.opportunities]: 1
    }
  };
  confirmBoardDelete: boolean = false;
  deletedBoardDetails: any = {};
  deletingBoard: boolean = false;
  boardAccountTooltipIndex: number = -1;
  varName: string | null = sessionStorage.getItem("accountName");
  businessLineOptions: any = [];

  created(){
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    const response: any = await financialBoardsRepo.find(
      this.filters,
      this.sort,
      this.pageNumber
    );

    if (response.STATUS === 1) {
      this.details = response;
      this.isSearch = this.details.ISSEARCH;
      this.totalAll = this.details.TOTALALL;
      this.totalBoardItems = this.details.TOTALBOARDITEMS;
      this.boardList = this.details.BOARDLIST;
      this.businessLineOptions = (this.details.businessLineOptions || []).map(item => ({
        ID: item.ID,
        TEXT: item.BUSINESSLINENAME,
        data: item
      }));
    }
    this.loading = false;
  }

  async reload() {
    this.addFinancialBoardModalVisible = false;
    await this.fetchData();
  }

  sorting(c) {
    switch (c) {
      case "BOARDNAME":
        this.sortingByBoardName();
        break;
      case "RANKING":
        this.sortingByRanking();
        break;
    }
  }

  async sortBy(field: BoardSortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingByBoardName() {
    this.sortBy(BoardSortField.boardName);
  }

  async sortingByRanking() {
    this.sortBy(BoardSortField.ranking);
  }

  async searchByBoardName() {
    this.pageNumber = 1;
    if (this.searchFilters.boardName == "") {
      return;
    } else {
      this.filters.boardName = this.searchFilters.boardName;
      await this.fetchData();
    }
  }

  async resetSearchByBoardName() {
    this.pageNumber = 1;
    this.filters.boardName = this.searchFilters.boardName = "";
    await this.fetchData();
  }

  async deleteBoard() {
    if (this.deletedBoardDetails.BOARDUUID) {
      this.deletingBoard = true;
      const response: any = await financialBoardsRepo.deleteBoard(this.deletedBoardDetails.BOARDUUID);

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        await this.fetchData();
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }

      this.deletingBoard = false;
      this.confirmBoardDelete = false;
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= (this.details.TOTALPAGES || 1) && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async searchByRank() {
    this.pageNumber = 1;
    this.filters.rankMin = this.searchFilters.rankMin;
    this.filters.rankMax = this.searchFilters.rankMax;
    await this.fetchData();
  }

  async resetSearchByRank() {
    this.pageNumber = 1;
    this.filters.rankMin = this.searchFilters.rankMin = undefined;
    this.filters.rankMax = this.searchFilters.rankMax = undefined;
    await this.fetchData();
  }

  checkAccTooltip(index) {
    checkTooltip($(this.$refs[`boardAccountTooltip${index}`]), this.boardList.length);
  }
}
