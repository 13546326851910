



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, printHtml, wait, downloadFileUrl } from "../models/common";
import { validateFn } from "../helpers";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "@/helpers/all";
import HardwareDetails from "@/components/Hardware/HardwareDetails.vue";
import RefreshStore from "../components/RefreshStore.vue";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import AssignToEndUserModal from "../components/AssignToEndUserModal.vue";
import ConfirmYesNoTooltip from "../components/ConfirmYesNoTooltip.vue";
import Datepicker from "../components/Datepicker.vue";
import DropdownControl from "../components/DropdownControl.vue";
import HardwareDevicesComponent from "../components/HardwareDevices.vue";
import moment from "moment";
import HardwareStatusComponent, {
  HardwareStatusValue
} from "@/components/HardwareStatus.vue";

import {
  getRangeFilter,
  colsResizeable,
  colsResizeableReset,
  colsResizeableUpdate,
  getDateRangeFilter,
  getEUAddress
} from "@/helpers/ApiHelper";
import draggable from "vuedraggable";

@Component({
  inheritAttrs: false,
  components: {
    HardwareDetails,
    Pagination,
    draggable,
    RefreshStore,
    ExportModal,
    ConfirmRemoveItemModal,
    AssignToEndUserModal,
    ConfirmYesNoTooltip,
    Datepicker,
    DropdownControl,
    HardwareDevicesComponent,
    HardwareStatusComponent
  },
  beforeRouteUpdate(to, from, next) {
    const self: any = this as any;
    const query = to.query;
    self.filters.sku = self.searchFilters.sku = query.sku;
    self.filters.customerPO = self.searchFilters.customerPO = query.poId || "";
    self.filters.endUser = self.searchFilters.endUser = query.endUser || "";
    self.filters.category = self.searchFilters.category = query.category || "";
    const view = query.view || "";
    const viewtype = query.viewtype || "";
    if (view == "All" || view == "Group") {
      self.viewType = view;
    } else if (viewtype == "EndUser") {
      self.viewType = viewtype;
    }
    self.pageNumber = 1;
    // self.setDefaultSelectedHeaders();
    self.fetchData();
    next();
  },
  beforeDestroy() {
    $('body').removeClass('stop-scrolling');
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    },
    getEUAddress
  }
})
export default class Hardwares extends TSXComponent<void> {
  // only got data if open from hardware import page
  @Prop({ required: false, default: [] })
  importHardwareIds?: any[];
  editableRows = false;
  isSaving = false;
  currentRole = sessionStorage.getItem("userRole");
  selectedID: any = [];
  selectedHardware: any = [];
  refreshStoreModalVisible = false;
  saving = false;
  loading = false;
  hardwareCheckAll = false;
  selectedAll = false;
  selectPageAll = false;
  newPageHardware: (number | undefined)[] = [];
  totalHardwares: (number | undefined)[] = [];
  excludedIDs: number[] = [];
  totalHardwareItems = 0;
  totalAll = 0;
  items: any = {};
  categories: any = {};
  status: any = [
    { ID: "Deleted", TEXT: "Deleted" },
    { ID: "Active", TEXT: "Active" },
    { ID: "Retired", TEXT: "Retired" }
  ];
  showDeletedFlg: boolean = false;
  warningMessage = "";
  inputField = "";
  details: any = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    TOTALALERTS: 0,
    searchByAsset: "",
    hardwareSKU: "",
    userHardwareHeaders: [],
    ISSEARCH: 0
  };
  filters: any = {
    status: [0,1,2],
    hardwareId: "",
    customerPO: "",
    sku: "",
    category: "",
    assetNumber: "",
    assetTag: "",
    customer: "",
    endUser: "",
    endUserNotNull: false,
    warrantyName: "",
    fromStartDate: "",
    endStartDate: "",
    fromExpDate: "",
    endExpDate: "",
    fromPurchasedOn: "",
    endPurchasedOn: "",
    fromLastCheckDate: "",
    endLastCheckDate: "",
    fromLastOnline: "",
    endLastOnline: "",
    lastOnlineNotNull: false,
    purchaseIds: [],
    purchaseId: "",
    productLine: "",
    statusNames: ['Deleted','Active','Retired']
  };
  pageNumber = 1;
  sort: any = {
    field: null,
    direction: {
      sku: 1,
      category: 1,
      price: 1,
      poId: 1,
      assetNumber: 1,
      status: 1,
      accountName: 1,
      purchasedOn: 1,
      endUser: 1,
      lastOnline: 1,
      warrantyName: 1,
      startDate: 1,
      warrentyExp: 1,
      lastChecked: 1,
      purchaseId: 1,
      productLine: 1
    }
  };
  directionField = "";

  pageHeaders: any = [
    { key: "HARDWAREID", value: "Hardware Id" },
    { key: "SKU", value: "Sku" },
    { key: "HDESCRIPTION", value: "Description" },
    { key: "ASSETNUMBER", value: "Serial Number" },
    { key: "CATEGORYNAME", value: "Category" },
    { key: "PURCHASEID", value: "VAR360 Order" },
    { key: "PRODUCTLINE", value: "Product Line" },
    { key: "POID", value: "Customer PO" },
    { key: "HSTATUS", value: "Status" },
    { key: "PURCHASEDON", value: "Created Date" },
    { key: "DEVICES", value: "Devices" },
    { key: "ASSETTAG", value: "Asset Tag" },
    { key: "ENDUSER", value: "End User" },
    { key: "ADDRESS", value: "User Location" },
    { key: "PHONE", value: "Phone Number" },
    { key: "EMAIL", value: "Email" },
    { key: "LASTONLINE", value: "Last Online" },
    { key: "LASTSEEN", value: "Last Location" },
    { key: "WARRANTYNAME", value: "Warranty Name" },
    { key: "STARTDATEFORMATTED", value: "Warranty Start Date" },
    { key: "WARRENTYEXPFORMATTED", value: "Warranty End Date" },
    { key: "LASTCHECKEDFORMATTED", value: "Warranty Last Checked" },
    { key: "MAINMODEL", value: "Main Model" }
  ];

  headerCustomer = [
    "HARDWAREID",
    "SKU",
    "HDESCRIPTION",
    "ASSETNUMBER",
    "CATEGORYNAME",
    /* "PRICEFORMATTED", */
    "PURCHASEID",
    "PRODUCTLINE",
    "POID",
    "HSTATUS",
    "ACCOUNTNAME",
    "PURCHASEDON",
    "DEVICES",
    "ASSETTAG",
    "ENDUSER",
    "ADDRESS",
    "PHONE",
    "EMAIL",
    "LASTONLINE",
    "LASTSEEN",
    "WARRANTYNAME",
    "STARTDATEFORMATTED",
    "WARRENTYEXPFORMATTED",
    "LASTCHECKEDFORMATTED",
    "MAINMODEL"
  ];
  selectedHeaders: string[] = [];
  // By default, we should be in the "grouped" view for hardware
  viewType = "Group";
  selectedView = false;
  assignEndUsersModalVisible = false;

  searchFilters: any = {
    status: [0,1,2],
    hardwareId: "",
    customerPO: "",
    sku: "",
    category: "",
    assetNumber: "",
    assetTag: "",
    customer: "",
    endUser: "",
    warrantyName: "",
    fromStartDate: "",
    endStartDate: "",
    fromPurchasedOn: "",
    endPurchasedOn: "",
    fromLastOnline: "",
    endLastOnline: "",
    fromExpDate: "",
    endExpDate: "",
    fromLastCheckDate: "",
    endLastCheckDate: "",
    purchaseIds: [],
    purchaseId: "",
    productLine: "",
    statusNames: ['Deleted','Active','Retired']
  };

  listCustomFields: any = [];

  headerOrder = [
    "HARDWAREID",
    "SKU",
    "HDESCRIPTION",
    "ASSETNUMBER",
    "CATEGORYNAME",
    /* "PRICEFORMATTED", */
    "PURCHASEID",
    "PRODUCTLINE",
    "POID",
    "HSTATUS",
    "ACCOUNTNAME",
    "PURCHASEDON",
    "DEVICES",
    "ASSETTAG",
    "ENDUSER",
    "ADDRESS",
    "PHONE",
    "EMAIL",
    "LASTONLINE",
    "LASTSEEN",
    "WARRANTYNAME",
    "STARTDATEFORMATTED",
    "WARRENTYEXPFORMATTED",
    "LASTCHECKEDFORMATTED",
    "MAINMODEL"
  ];
  allowEndUserAll = false;
  withIdentifiersOnly = false;
  exportModalVisible = false;
  confirmRemoveModalVisible = false;
  confirmRestoreModalVisible = false;
  searchHeader = "";
  selectedItems: any = [];
  isLoaded = false;
  processed = {
    showVar360Order: false
  };
  confirmAssignVisible = false;
  dateError = false;
  checkingWarranties = false;
  progressBarWidth = 0;
  totalWarranties = 0;
  checkedWarranties = 0;
  cancelCurrentAnimation = false;
  animationFrameId: any = null;
  deletedView: boolean = false;

  @Watch("selectedHeaders")
  colsChange(val: any) {
    colsResizeable();
  }

  async created() {
    if (this.currentRole === "Reseller") {
      this.pageHeaders.push({ key: "ACCOUNTNAME", value: "Customer" });
    }

    const urlParams = window.location.hash;

    if (
      urlParams == "#/hardware?viewtype=EndUser" ||
      urlParams.startsWith("#/hardware?viewtype=EndUser")
    ) {
      this.viewType = "EndUser";
      this.allowEndUserAll = true;
    } else if (urlParams.startsWith("#/hardware?sku=")) {
      this.viewType = "All";
      this.allowEndUserAll = true;
    } else if (urlParams.startsWith("#/hardware?deploymentPurchaseId=")) {
      this.viewType = "All";
      this.allowEndUserAll = true;
    } else if (urlParams.startsWith("#/hardware?searchAccountName=")) {
      this.viewType = "All";
      this.allowEndUserAll = true;
    } else {
      this.viewType = "Group";
    }

    // init params
    const query = this.$route.query;
    if ((query.sku || "") != "") {
      this.filters.sku = this.searchFilters.sku = query.sku;
    }
    if ((query.poId || "") != "") {
      this.filters.customerPO = this.searchFilters.customerPO = query.poId;
    }
    if ((query.searchAccountName || "").length) {
      this.filters.customer = this.searchFilters.customer =
        query.searchAccountName;
    }
    if ((query.purchaseId || "") != "") {
      this.filters.purchaseId = this.searchFilters.purchaseId = `${
        query.purchaseId
      }`;
    }
    if (query.deploymentPurchaseId) {
      if (typeof query.deploymentPurchaseId === "string") {
        this.filters.purchaseIds = [parseInt(query.deploymentPurchaseId)];
      } else if (Array.isArray(query.deploymentPurchaseId)) {
        this.filters.purchaseIds = query.deploymentPurchaseId.map(s =>
          parseInt(s || "0")
        );
      }
      const currentQueryParam = { ...query };
      this.$delete(currentQueryParam, "deploymentPurchaseId");
      this.$delete(currentQueryParam, "view");
      this.$router.replace({ query: currentQueryParam });
    }
    if (query.hardwareid) {
      this.filters.hardwareId = this.searchFilters.hardwareId = this.$route.query.hardwareid;
      const currentQueryParam = { ...query };
      this.$delete(currentQueryParam, "hardwareid");
      this.$router.replace({ query: currentQueryParam });
    }
    const view = query.view || "";
    if (view == "All" || view == "Group") {
      this.viewType = view;
    }

    if (query.pageName && query.pageName === 'HardwareDashboard' ) {
      this.viewType = 'All';
      this.withIdentifiersOnly = true;
      this.filters.endUserNotNull = true;
      this.allowEndUserAll = true;
    }

    this.setDefaultSelectedHeaders();
    await this.fetchData();

    if (
      !this.processed.showVar360Order &&
      (query.showVar360Order || "") != ""
    ) {
      // auto select var360 order column
      if (!this.selectedHeaders.includes("PURCHASEID")) {
        this.selectedHeaders.push("PURCHASEID");
        this.sortSelectedHeader();
        this.addHeader("PURCHASEID");
      }
      this.processed.showVar360Order = true;
    }
  }

  setDefaultSelectedHeaders() {
    this.selectedHeaders = [
      "HARDWAREID",
      "SKU",
      "HDESCRIPTION",
      "ASSETNUMBER",
      "CATEGORYNAME",
      "PURCHASEID",
      "POID",
      "HSTATUS"
    ];
  }

  isRecent(logts) {
    let logDate: any = new Date(logts);
    let now: any = new Date();
    let diffInHours = Math.abs(now - logDate) / 36e5; // divide by (1000*60*60) to get difference in hours
    return diffInHours < 8;
  }

  hasFilterCondition() {
    if (this.viewType === "EndUser") {
      if (
        this.filters.endUser ||
        this.filters.customerPO ||
        this.filters.customer ||
        this.filters.purchaseId
      ) {
        return true;
      }
    } else if (this.viewType === "Group") {
      if (
        this.filters.customerPO ||
        this.filters.sku ||
        this.filters.category ||
        this.filters.fromPurchasedOn ||
        this.filters.endPurchasedOn ||
        this.filters.purchaseId ||
        this.filters.assetNumber
      ) {
        return true;
      }
    } else if (this.viewType === "All") {
      if (
        this.filters.hardwareId ||
        this.filters.customer ||
        this.filters.customerPO ||
        this.filters.endUser ||
        this.filters.sku ||
        this.filters.category ||
        this.filters.assetNumber ||
        this.filters.assetTag ||
        this.filters.warrantyName ||
        this.filters.fromStartDate ||
        this.filters.endStartDate ||
        this.filters.fromExpDate ||
        this.filters.endExpDate ||
        this.filters.fromPurchasedOn ||
        this.filters.endPurchasedOn ||
        this.filters.purchaseId ||
        this.filters.endUser ||
        this.filters.status.length ||
        this.filters.purchaseIds.length ||
        this.filters.productLine ||
        this.filters.fromLastCheckDate ||
        this.filters.endLastCheckDate
      ) {
        return true;
      }
    }

    return false;
  }

  allHardwareHeaders = {};

  async fetchData() {
    this.hasChangedStatusFilters = false;
    const urlParams = window.location.hash;
    this.loading = true;
    let showDeleted = false;

    if (this.importHardwareIds && this.importHardwareIds.length > 0) {
      this.viewType = "All";
      this.selectedView = true;
    }

    // || this.filters.status != ''
    if (this.deletedView) {
      showDeleted = true;
    } else {
      showDeleted = false;
    }
    try {
      let requestData: any = {
        controller: "Hardware",
        FunctionName: "List",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        order: this.sort.field ? this.sort.field : 0,
        direction: this.sort.field
          ? this.sort.direction[this.directionField]
          : 0,
        viewType:
          showDeleted ||
          (this.importHardwareIds && this.importHardwareIds.length > 0)
            ? "All"
            : this.viewType,
        showDeletedFlg: showDeleted ? 1 : 0,
        endUserNotNull: this.filters.endUserNotNull ? 1 : 0,
        lastOnlineNotNull: this.filters.lastOnlineNotNull ? 1 : 0,
        fromLastOnline: this.filters.fromLastOnline,
        endLastOnline: this.filters.endLastOnline,
        selectedView: this.selectedView,
        allowEndUserAll: this.allowEndUserAll,
        withIdentifiersOnly: this.withIdentifiersOnly,
        purchaseIds: this.filters.purchaseIds.join(","),
        importHardwareIds: this.importHardwareIds
          ? this.importHardwareIds.join()
          : "",
        purchaseId: this.filters.purchaseId || "",
        customer: this.filters.customer || "",
        customerPO: this.filters.customerPO || "",
        status: this.filters.status.length
          ? this.filters.status.join(",")
          : "-1"
      };
      switch (this.viewType) {
        case "EndUser":
          requestData = {
            ...requestData,
            endUser: this.filters.endUser,
          };
          break;
        case "Group":
          requestData = {
            ...requestData,
            customerPO: this.filters.customerPO,
            sku: this.filters.sku,
            category: this.filters.category,
            fromPurchasedOn: this.filters.fromPurchasedOn,
            endPurchasedOn: this.filters.endPurchasedOn,
            assetNumber: this.filters.assetNumber,
          };
          break;
        default:
          requestData = {
            ...requestData,
            hardwareId: this.filters.hardwareId,
            customer: this.filters.customer,
            customerPO: this.filters.customerPO,
            endUser: this.filters.endUser,
            sku: this.filters.sku,
            category: this.filters.category,
            assetNumber: this.filters.assetNumber,
            assetTag: this.filters.assetTag,
            warrantyName: this.filters.warrantyName,
            fromStartDate: this.filters.fromStartDate,
            endStartDate: this.filters.endStartDate,
            fromExpDate: this.filters.fromExpDate,
            endExpDate: this.filters.endExpDate,
            fromPurchasedOn: this.filters.fromPurchasedOn,
            endPurchasedOn: this.filters.endPurchasedOn,
            fromLastCheckDate: this.filters.fromLastCheckDate,
            endLastCheckDate: this.filters.endLastCheckDate,
            productLine: this.filters.productLine
          };
          break;
      }
      const response = await axios.post(
        dataURL + "?ReturnType=JSON",
        requestData
      );

      // clear after search by imported hardwares
      if (this.importHardwareIds && this.importHardwareIds.length > 0) {
        this.importHardwareIds = [];
      }

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1) {
        console.log(response.data.CATEGORIES);
        this.allHardwareHeaders = response.data.allHardwareHeaders || {};
        this.items = response.data.HARDWARES || [];
        this.categories = response.data.CATEGORIES || [];
        this.totalHardwareItems = response.data.HARDWARES.length;
        this.totalAll = response.data.TOTAL;
        this.items.forEach(item => {
          if (item.LOGTS.length) {
            let parsedDate = this.parseCustomDate(item.LOGTS);
            item.LOGTS = this.formatDateAndTime(parsedDate);
          }
        });

        this.details = response.data || {};

        // set selected status
        // const checkAllPages = this.newPageHardware.includes(this.pageNumber);
        // if (checkAllPages)  {
        //   this.totalHardwares = [];
        //   for (const val of this.items) {
        //     if (!this.excludedIDs.includes(val.HARDWAREID)) {
        //       this.totalHardwares.push(val.PLID);
        //     }
        //   }
        //   const intersectingArrays = this.getArraysIntersection(this.totalHardwares, this.selectedID);
        //   if (intersectingArrays.length == this.items.length) {
        //     this.hardwareCheckAll = true;
        //   } else {
        //     this.hardwareCheckAll = false;
        //   }
        // }
        // else {
        //   this.hardwareCheckAll = false;
        // }
        if (this.selectedAll === true) {
          this.selectAll(true);
        }
        this.totalHardwares = [];
        for (const val of this.items) {
          if (this.selectedID.includes(val.HARDWAREID)) {
            this.totalHardwares.push(val.HARDWAREID);
          }
        }
        if (this.items.length == this.totalHardwares.length) {
          this.hardwareCheckAll = true;
          this.selectPageAll = true;
        } else {
          this.hardwareCheckAll = false;
          this.selectPageAll = false;
        }

        if (
          !this.selectedView &&
          this.details["currentView"] &&
          !urlParams.startsWith("#/hardware?sku=") &&
          !urlParams.startsWith("#/hardware?viewtype=EndUser")
        ) {
          this.selectedView = true;
          this.viewType = this.details["currentView"];
        }
        // if (this.viewType == "Group") {
        //   this.selectedHeaders = [
        //     "SKU",
        //     "HDESCRIPTION",
        //     "DEVICES",
        //     "POID",
        //     "CATEGORYNAME",
        //     /* "PRICEFORMATTED", */
        //     "ASSETNUMBER",
        //     "PURCHASEDON"
        //   ];
        // } else if (this.viewType == "EndUser") {
        //   this.selectedHeaders = [
        //     "ENDUSER",
        //     "ADDRESS",
        //     "PHONE",
        //     "MAINMODEL",
        //     "EMAIL",
        //     "DEVICES",
        //     "LASTONLINE",
        //     "LASTSEEN"
        //   ];
        // } else {
        // complete view
        // selected hardware headers
        const savedHeaders = this.details.userHardwareHeaders || [];
        if (savedHeaders.length) {
          this.selectedHeaders = [];
          for (const item of savedHeaders) {
            const inList = this.pageHeaders.find(
              t => t.key.toLowerCase() == item.toLowerCase()
            );
            if (inList) {
              // if found in main columns list
              this.selectedHeaders.push(inList.key);
            }
          }
          if (!this.selectedHeaders.length) {
            this.setDefaultSelectedHeaders();
          }
        } else {
          this.setDefaultSelectedHeaders();
        }

        // if sorted header
        const savedSorting = this.details.userHardwareColumnOrder || [];
        if (savedSorting.length) {
          let newSorting: any = [];
          for (const header of savedSorting) {
            const inList = this.pageHeaders.find(item => item.key == header);
            if (inList) {
              newSorting.push(inList);
            }
          }
          const newItems = this.pageHeaders.filter(
            item => !savedSorting.includes(item.key)
          );
          newSorting = [...newSorting, ...newItems];
          this.pageHeaders = newSorting;

          // need to sort selectedHeaders
          this.sortSelectedHeader();
        }
      }
      colsResizeableUpdate({ ms: 300 });
      // }
      this.isLoaded = true;
      this.checkColsWidth();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  parseCustomDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let [monthStr, day, year, time, offset] = dateString.split(" ");
    let [hours, minutes, seconds] = time.split(":");
    let month = months.indexOf(monthStr.replace(",", ""));
    return new Date(
      Date.UTC(year, month, day, hours, minutes, seconds) -
        parseInt(offset) * 36
    );
  }

  formatDateAndTime(dateObj) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    let month = months[dateObj.getMonth()];
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();

    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  }

  showModal() {
    this.refreshStoreModalVisible = true;
  }

  sortSelectedHeader() {
    const newSelectedHeaders: string[] = [];
    for (const item of this.pageHeaders) {
      if (!this.selectedHeaders.includes(item.key)) continue;
      newSelectedHeaders.push(item.key);
    }
    this.selectedHeaders = newSelectedHeaders;
  }

  getExportCondition() {
    let exportCondition: any = {};
    exportCondition.allowEndUserAll = this.allowEndUserAll;
    exportCondition.withIdentifiersOnly = this.withIdentifiersOnly;

    switch (this.viewType) {
      case "EndUser":
        exportCondition = {
          ...exportCondition,
          customerPO: this.filters.customerPO || "",
          customer: this.filters.customer || "",
          purchaseId: this.filters.purchaseId || "",
          endUser: this.filters.endUser
        };
        break;
      case "Group":
        exportCondition = {
          ...exportCondition,
          customerPO: this.filters.customerPO,
          sku: this.filters.sku,
          category: this.filters.category,
          fromPurchasedOn: this.filters.fromPurchasedOn || "",
          endPurchasedOn: this.filters.endPurchasedOn || "",
          purchaseId: this.filters.purchaseId || "",
          assetNumber: this.filters.assetNumber
        };
        break;
      default:
        exportCondition = {
          ...exportCondition,
          hardwareId: this.filters.hardwareId,
          customer: this.filters.customer,
          customerPO: this.filters.customerPO,
          endUser: this.filters.endUser,
          sku: this.filters.sku,
          category: this.filters.category,
          assetNumber: this.filters.assetNumber,
          assetTag: this.filters.assetTag,
          warrantyName: this.filters.warrantyName,
          fromStartDate: this.filters.fromStartDate,
          endStartDate: this.filters.endStartDate,
          fromExpDate: this.filters.fromExpDate,
          endExpDate: this.filters.endExpDate,
          fromLastCheckDate: this.filters.fromLastCheckDate,
          fromPurchasedOn: this.filters.fromPurchasedOn || "",
          endPurchasedOn: this.filters.endPurchasedOn || "",
          productLine: this.filters.productLine || "",
          status: this.filters.status.length
            ? this.filters.status.join(",")
            : undefined,
          purchaseIds: this.filters.purchaseIds.join(","),
          purchaseId: this.filters.purchaseId || "",
          endLastCheckDate: this.filters.endLastCheckDate
        };
        break;
    }

    return exportCondition;
  }

  async pdfDownLoadFn(selectedID) {
    let exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "PDF";
    exportObj["viewType"] = this.viewType;
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["selectedAll"] = this.selectedAll;
    const exportCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...exportCondition
    };

    await this.downloadProcess(exportObj);
  }

  async csvDownLoadFn(selectedID) {
    let exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "CSV";
    exportObj["viewType"] = this.viewType;
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["selectedAll"] = this.selectedAll;
    const exportCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...exportCondition
    };

    await this.downloadProcess(exportObj);
  }

  async htmlDownLoadFn(selectedID) {
    let exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "HTML";
    exportObj["viewType"] = this.viewType;
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["selectedAll"] = this.selectedAll;
    const exportCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...exportCondition
    };

    await this.downloadProcess(exportObj);
  }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;

    if (formats.includes(ExportFormat.csv)) {
      await this.csvDownLoadFn(this.selectedID);
    }

    if (formats.includes(ExportFormat.html)) {
      await this.htmlDownLoadFn(this.selectedID);
    }

    if (formats.includes(ExportFormat.pdf)) {
      await this.pdfDownLoadFn(this.selectedID);
    }

    this.loading = false;
  }

  printFn(selectedID) {
    var exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "PDF";
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["Print"] = "Yes";

    const printCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...printCondition
    };

    this.printProcess(exportObj);
  }

  async printProcess(data) {
    this.loading = true;
    let $this = this;
    var downLoadRes = getRouteData(data);
    downLoadRes.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS) {
          $this.loading = false;
          let html = response.data.HTMLDATA;
          if (!html) return;

          printHtml(html);
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("hardware.js : csvDownLoadFn error : ", e);
      }
    });
  }

  async deleteOrRestoreSelectedHardwares(isActive = 0) {
    this.confirmRemoveModalVisible = false;
    this.confirmRestoreModalVisible = false;

    try {
      this.loading = true;

      // group selected items by aId
      const toggleItems: any = [];
      for (const item of this.selectedItems) {
        const inList = toggleItems.find((t: any) => t.AID == item.AID);
        if (!inList) {
          toggleItems.push({
            AID: item.AID,
            HARDWAREID: this.selectedItems
              .filter((t: any) => t.AID == item.AID)
              .map(t => t.HARDWAREID)
          });
        }
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Hardware",
        FunctionName: "DeleteRestore",
        userID: sessionStorage.getItem("userId"),
        hardwareIDs: this.selectedID,
        isActive: isActive, // delete if isActive = 0, restore if isActive = 1
        toggleItems
      });

      if (response.data.STATUS == 1) {
        this.selectedID = [];
        this.selectedItems = [];
        this.selectedAll = false;
        this.selectPageAll = false;
        this.excludedIDs = [];
        this.totalHardwares = [];
        this.hardwareCheckAll = false;
        this.totalHardwareItems = 0;
        this.newPageHardware = [];

        if (isActive === 1) {
          await this.fetchData(); // reload deleted data
        } else {
          await this.fetchData(); // reload data
        }
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      this.loading = false;
    }
  }

  searchBy(field) {
    const words = field.trim().split(/\s+/);
    if (words.length === 1) {
      if (this.filters[field] == "") {
        return;
      }
      if (this.filters[field].length) {
        this.searchFilters[field] = this.filters[field];
      }
      this.pageNumber = 1;
      this.fetchData();
    } else {
      // this.filters.endUser = "notnull";
      this.fetchData();
    }
  }

  resetBy(field) {
    if (this.filters[field] == "") {
      return;
    }
    if (this.searchFilters[field].length) {
      this.searchFilters[field] = "";
    }
    this.pageNumber = 1;
    this.filters[field] = "";
    this.fetchData();
  }

  updateValue(field) {
    if (this.filters[field] == "") {
      this.pageNumber = 1;
      this.fetchData();
    }
  }

  async downloadProcess(data) {
    const downLoadRes = getRouteData(data);
    const response = await downLoadRes;

    try {
      if (response.data.STATUS) {
        // const link = document.createElement("a");
        // link.download = data["ExportType"];
        // link.href = response.data.S3URL;
        // console.log('downloadProcess ', link);
        // link.click();
        downloadFileUrl(response.data.S3URL);
      }
      if (response.data.STATUS !== 1) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
      if (response.data.ERROR) {
        console.log(response.data.ERROR);
      }
    } catch (err) {
      console.log(err);
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      if (this.showDeletedFlg) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    }
  }

  allowRemoveColumn(header) {
    if (this.currentRole != "Reseller") {
      return false;
    }
    let ret = false;
    if (
      this.viewType == "All" &&
      ![
        "HARDWAREID",
        "SKU",
        "HDESCRIPTION",
        "ASSETNUMBER",
        "CATEGORYNAME",
        "POID",
        "HSTATUS"
      ].includes(header)
    ) {
      ret = true;
    } else if (
      this.viewType == "EndUser" &&
      !["ENDUSER", "DEVICES"].includes(header)
    ) {
      ret = true;
    }

    return ret;
  }

  removeColumn(header) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerOrder) {
      const valIdx: string = this.headerOrder[ts];
      const i = this.selectedHeaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.addHeader(header);
  }

  getListHeader(key: string) {
    let ret = "";
    if (key.indexOf("customData") != -1) {
      const fieldId = key.replace("customData", "");
      const inList = this.listCustomFields.find(
        item => item.CUSTOMFIELDID == fieldId
      );
      if (inList) {
        ret = inList.CUSTOMFIELDNAME || "";
      }
    } else {
      // for (var prop in this.pageHeaders) {
      //   if (this.pageHeaders[prop] === key) {
      //     // or do whatever you need with the value
      //     ret = this.pageHeaders[prop];
      //   }
      // }
      const inList = this.pageHeaders.find(
        item => item.key.toLowerCase() == key.toLowerCase()
      );
      if (inList) {
        ret = inList.value;
      }
    }
    return ret;
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async addHeader(c) {
    const addedCol = this.selectedHeaders.includes(c);
    colsResizeableReset();
    var sortedHeaders: string[] = [];
    for (var customerHeader in this.headerCustomer) {
      var customerIndex: string = this.headerCustomer[customerHeader];
      const selectedIndex = this.selectedHeaders.includes(customerIndex);
      if (selectedIndex) {
        sortedHeaders.push(customerIndex);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.sortSelectedHeader();
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "hardware",
      viewType: this.viewType
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    if (addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      await this.fetchData();
    }
    colsResizeable();
    if (!$("body").hasClass("expand-full")) {
      $(".tablesorter.hasResizable").attr(
        "data-width",
        $(".tablesorter.hasResizable").width()
      );
    }
    this.checkColsWidth();
  }

  hardwareCheckAllChange() {
    this.hardwareCheckAll = !this.hardwareCheckAll;
    if (!this.hardwareCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
    this.checkExcludedIDs(this.items, "HARDWAREID");
  }

  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    // this.showFilter = 0;
    if (this.selectPageAll == true) {
      const selectedPage = this.pageNumber;
      this.newPageHardware.push(selectedPage);
      for (const val of this.items) {
        if (!this.excludedIDs.includes(val.HARDWAREID)) {
          if (this.selectedID.indexOf(val.HARDWAREID) == -1) {
            this.selectedID.push(val.HARDWAREID);
            this.totalHardwares.push(val.HARDWAREID);
          }
        }
      }
    } else {
      for (const val of this.items) {
        let selectedIDIndex = this.selectedID.indexOf(val.HARDWAREID);
        let totalLedgersIndex = this.totalHardwares.indexOf(val.HARDWAREID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalHardwares.splice(totalLedgersIndex, 1);
      }
      let newPageLedgerIndex = this.newPageHardware.indexOf(this.pageNumber);
      this.newPageHardware.splice(newPageLedgerIndex, 1);
    }
  }

  checkExcludedIDs(items, key, hardwareID = 0) {
    if (this.selectedAll || this.selectPageAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
            this.hardwareCheckAll = false;
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.totalHardwareItems) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
    if (hardwareID) {
      if (this.selectedID.includes(hardwareID)) {
        this.totalHardwares.push(hardwareID);
      } else {
        this.$delete(
          this.totalHardwares,
          this.totalHardwares.findIndex(id => id == hardwareID)
        );
      }
    }
    if (
      this.items.length == this.totalHardwares.length &&
      this.items.length != 0
    ) {
      this.hardwareCheckAll = true;
      this.selectPageAll = true;
    } else {
      this.hardwareCheckAll = false;
      this.selectPageAll = false;
    }
  }

  getSelectedTotal(total) {
    let ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }
    return ret;
  }

  async handleShowDeleted() {
    this.pageNumber = 1;
    this.viewType = "All";
    this.deletedView = true;
    this.selectedID = [];
    this.selectedAll = false;
    this.selectPageAll = false;
    this.excludedIDs = [];
    this.totalHardwares = [];
    this.hardwareCheckAll = false;
    this.totalHardwareItems = 0;
    this.newPageHardware = [];

    await this.fetchData();
    this.showDeletedFlg = true;
  }

  getArraysIntersection(getHardwaresOne, getHardwaresTwo) {
    return getHardwaresOne.filter(function(n) {
      return getHardwaresTwo.indexOf(n) !== -1;
    });
  }

  modalHardwareDetails: {
    show: boolean;
    isLoading: boolean;
    data: any;
    reset: Function;
  } = {
    show: false,
    isLoading: false,
    data: {},
    reset: () => {
      this.modalHardwareDetails.data = {
        id: 0,
        name: "",
        imageURL: "",
        hardwareImages: [],
        sku: "",
        statusName: "",
        statusId: 0,
        order: {
          purchaseId: 0,
          poId: "N/A"
        },
        customer: {
          aId: 0,
          aName: "N/A",
          accountId: ""
        },
        deployment: {
          deploymentId: 0,
          deploymentName: "N/A"
        },
        categoryId: 0,
        categoryName: "",
        carrier: "",
        trackingNumber: "N/A",
        endUser: {
          userId: 0,
          name: "N/A",
          addr: "",
          cityZip: ""
        },
        identifiers: [
          {
            name: "VAR360 Hardware ID",
            type: "hardware_id",
            value: "N/A"
          },
          {
            name: "Serial Number",
            type: "asset_number",
            value: ""
          },
          {
            name: "Asset Tag",
            type: "asset_tag",
            value: ""
          }
        ],
        logs: [],
        warranty: [
          {
            name: "Warranty Name",
            type: "warranty_name",
            value: "N/A"
          },
          {
            name: "Start Date",
            type: "start_date",
            value: ""
          },
          {
            name: "End Date",
            type: "end_date",
            value: ""
          },
          {
            name: "Last Checked",
            type: "last_checked",
            value: ""
          }
        ]
      };
    }
  };

  checkDuplicateExists(header, item) {
    if (item[header] != "") {
      const response = axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        header: header,
        value: item[header],
        hardwareId: item.HARDWAREID,
        action: "checkDuplicate"
      });
      response.then(response => {
        this.warningMessage = response.data.STATUSMESSAGE;
        this.inputField = response.data.header;
      });
    }
  }

  async showModalDetails(
    item,
    preventClick = false,
    options: any = {},
    e: any
  ) {
    if (preventClick) return;
    if (e && $(e.target).hasClass("eu-hardware-link")) {
      return;
    }

    if (this.viewType == "Group" && (item.QUANTITY || 0) > 1) {
      // back to "complete view", filter by poId, sku
      // this.viewType = "All";
      // this.filters.sku = this.searchFilters.sku = item.SKU;
      // this.filters.customerPO = this.searchFilters.customerPO = item.POID;
      //this.fetchData();
      if (this.$route.query.sku) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            sku: item.SKU,
            poId: item.POID,
            view: "All",
            tok: ApiHelper.getRandomNumber(100000, 999999) + ""
          }
        });
      } else {
        this.$router.push({
          name: this.$route.name,
          query: {
            sku: item.SKU,
            poId: item.POID,
            view: "All",
            tok: ApiHelper.getRandomNumber(100000, 999999) + ""
          }
        });
      }

      return;
    } else if (this.viewType == "EndUser" && (item.QUANTITY || 0) > 1) {
      // this.viewType = "All";
      // this.filters.endUser = this.searchFilters.endUser = item.ENDUSERFULLNAME;
      // this.fetchData();
      const query: any = {
        endUser: item.ENDUSERFULLNAME,
        view: "All",
        purchaseIds: item.PURCHASEIDS || "",
        tok: ApiHelper.getRandomNumber(100000, 999999) + ""
      };
      if (options.filters && (options.filters.category || "") != "") {
        query.category = options.filters.category;
      }

      if (this.$route.query.endUser) {
        this.$router.replace({
          name: this.$route.name,
          query
        });
      } else {
        this.$router.push({
          name: this.$route.name,
          query
        });
      }
      return;
    }

    this.modalHardwareDetails.isLoading = true;
    this.modalHardwareDetails.reset();
    const response = await ApiHelper.callApi("post", {
      controller: "Hardware",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: item.HARDWAREID,
      aId: item.AID || 0
    });
    this.modalHardwareDetails.show = true;
    if (response.STATUS != 1) {
      notifier.alert(response.STATUSMESSAGE);
      this.modalHardwareDetails.show = false;
      return;
    }

    const hardwareData = response.hardware || {};
    const hardwareLogs: any = response.hardwareLogs || [];

    // @Todo() Update product details data
    this.modalHardwareDetails.data = {
      ...this.modalHardwareDetails.data,
      id: hardwareData.HARDWAREID || 0,
      hardwareUUID: hardwareData.HARDWAREUUID || "",
      imageURL: hardwareData.IMAGEURL || "",
      hardwareStatus:
        hardwareData.ISACTIVE === 0
          ? "Deleted"
          : hardwareData.ISACTIVE === 1
          ? "Active"
          : hardwareData.ISACTIVE === 2
          ? "Retired"
          : "N/A",
      hardwareImages:
        (hardwareData.HARDWAREIMAGES || []).map(
          hImage => `${dataURL}/${hImage}`
        ) || [],
      name: hardwareData.HDESCRIPTION || "",
      sku: hardwareData.HARDWARESKU || "",
      statusName: hardwareData.HSTATUS || "",
      categoryId: hardwareData.CATEGORYID || 0,
      categoryName: hardwareData.CATEGORYNAME || "",
      order: {
        purchaseId: hardwareData.PURCHASEID || 0,
        poId: hardwareData.POID || "N/A"
      },
      customer: {
        aId: hardwareData.AID || 0,
        aName: hardwareData.ANAME || "N/A",
        accountId: hardwareData.ACCOUNTID || ""
      },
      deployment: {
        deploymentId: hardwareData.DEPLOYMENTID || 0,
        deploymentName: hardwareData.DEPLOYMENTNAME || "N/A"
      },
      endUser: {
        euID: hardwareData.EUID || 0,
        fname: hardwareData.UFNAME || "N/A",
        lname: hardwareData.ULNAME || "",
        phone: hardwareData.UPHONE || "",
        account: hardwareData.UACCOUNT || "",
        lastonline: hardwareData.LOGTS || "",
        address1: hardwareData.ADDRESS1 || "",
        address2: hardwareData.ADDRESS2 || "",
        city: hardwareData.CITY || "",
        state: hardwareData.STATE || "",
        zip: hardwareData.ZIPCODE || "",
        email: hardwareData.EMAIL || "",
        contactEuId: hardwareData.CONTACTEUID || 0
      },
      logs: {
        mostRecent: hardwareData.LOGS.length
          ? new Date(
              hardwareData.LOGS[hardwareData.LOGS.length - 1].LOGTS
            ).toLocaleString()
          : "",
        last10: hardwareData.LOGS.map(log => ({
          ...log,
          LOGTS: new Date(log.LOGTS).toLocaleString()
        }))
      },
      carrier: hardwareData.CARRIER || "",
      trackingNumber: hardwareData.TRACKINGNUMBER || "N/A"
    };
    // identifiers data
    for (const item of this.modalHardwareDetails.data.identifiers) {
      if (item.type == "hardware_id") {
        item.value = hardwareData.HARDWAREID || 0;
      } else if (item.type == "asset_number") {
        item.value = hardwareData.ASSETNUMBER || "";
      } else if (item.type == "asset_tag") {
        item.value = hardwareData.ASSETTAG || "";
      }
    }
    // warranty data
    for (const item of this.modalHardwareDetails.data.warranty) {
      if (item.type == "warranty_name") {
        item.value = hardwareData.WARRANTYNAME || "";
      } else if (item.type == "start_date") {
        item.value = hardwareData.STARTDATEFORMATTED || "";
      } else if (item.type == "end_date") {
        item.value = hardwareData.WARRENTYEXPFORMATTED || "";
      } else if (item.type == "last_checked") {
        item.value = hardwareData.LASTCHECKEDFORMATTED || "";
      }
    }
    setTimeout(() => {
      this.modalHardwareDetails.isLoading = false;
    }, 500);
  }

  // @Todo() update search function
  searchChange() {}

  toggleCustomView(currStatus) {
    this.showDeletedFlg = false;
    this.viewType = currStatus;
    this.deletedView = false;
    this.selectedView = true;
    this.pageNumber = 1;
    this.allowEndUserAll = false;
    this.selectedID = [];
    this.selectedItems = [];
    this.filters = {
      ...this.filters,
      hardwareId: "",
      customerPO: "",
      sku: "",
      category: "",
      assetNumber: "",
      assetTag: "",
      customer: "",
      endUser: "",
      endUserNotNull: false,
      warrantyName: "",
      fromStartDate: "",
      endStartDate: "",
      fromExpDate: "",
      endExpDate: "",
      fromPurchasedOn: "",
      endPurchasedOn: "",
      fromLastCheckDate: "",
      endLastCheckDate: "",
      fromLastOnline: "",
      endLastOnline: "",
      lastOnlineNotNull: false,
      purchaseIds: [],
      purchaseId: "",
      productLine: "",
    };

    this.searchFilters = {
      ...this.searchFilters,
      hardwareId: "",
      customerPO: "",
      sku: "",
      category: "",
      assetNumber: "",
      assetTag: "",
      customer: "",
      endUser: "",
      warrantyName: "",
      fromStartDate: "",
      endStartDate: "",
      fromPurchasedOn: "",
      endPurchasedOn: "",
      fromLastOnline: "",
      endLastOnline: "",
      fromExpDate: "",
      endExpDate: "",
      fromLastCheckDate: "",
      endLastCheckDate: "",
      purchaseIds: [],
      purchaseId: "",
      productLine: "",
    };
    if (currStatus == "EndUser") {
      const findSelectedHeaders = this.allHardwareHeaders[this.viewType] || "";
      if (findSelectedHeaders) {
        this.selectedHeaders = findSelectedHeaders.split(",");
      }
      this.sort.field = 0;
      this.directionField = "";
      this.$router.replace({
        name: this.$route.name,
        query: {
          viewtype: "EndUser",
          tok: ApiHelper.getRandomNumber(100000, 999999) + ""
        }
      });
    } else {
      this.fetchData();
    }
  }

  getFilterValue(header) {
    let searchKey: string | undefined = "";
    switch (header) {
      case "HARDWAREID":
        searchKey = this.searchFilters.hardwareId;
        break;
      case "SKU":
        searchKey = this.searchFilters.sku;
        break;
      case "ASSETNUMBER":
        searchKey = this.searchFilters.assetNumber;
        break;
      case "ASSETTAG":
        searchKey = this.searchFilters.assetTag;
        break;
      case "CATEGORYNAME":
        searchKey = this.searchFilters.category;
        break;
      case "PURCHASEID":
        searchKey = this.searchFilters.purchaseId;
        break;
      case "POID":
        searchKey = this.searchFilters.customerPO;
        break;
      case "ACCOUNTNAME":
        searchKey = this.searchFilters.customer;
        break;
      case "ENDUSER":
        {
          const tmpArr = [];
          if (this.filters.endUserNotNull) {
            tmpArr.push("Value Exists");
          }
          if (this.searchFilters.endUser) {
            tmpArr.push(this.searchFilters.endUser);
          }
          searchKey = tmpArr.join(" - ");
        }

        break;
      case "WARRANTYNAME":
        searchKey = this.searchFilters.warrantyName;
        break;
      case "STARTDATEFORMATTED":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromStartDate,
          this.searchFilters.endStartDate
        );
        break;
      case "WARRENTYEXPFORMATTED":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromExpDate,
          this.searchFilters.endExpDate
        );
        break;
      case "PURCHASEDON":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromPurchasedOn,
          this.searchFilters.endPurchasedOn
        );
        break;
      case "LASTONLINE":
      {
        const tmpArr = [];
        if(this.filters.lastOnlineNotNull) {
          tmpArr.push('Value Exists');
        }
        const tmpSearchKey = getDateRangeFilter(
            this.searchFilters.fromLastOnline,
            this.searchFilters.endLastOnline
        );
        if(tmpSearchKey) {
          tmpArr.push(tmpSearchKey);
        }
        searchKey = tmpArr.join(' - ');

      }
        break;
      case "LASTCHECKEDFORMATTED":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromLastCheckDate,
          this.searchFilters.endLastCheckDate
        );
        break;
      case "PRODUCTLINE":
        searchKey = this.filters.productLine;
        break;
      case "HSTATUS":
        if ( this.filters.statusNames && !this.filters.statusNames.length) searchKey = "None";
        else if (this.filters.statusNames && !(this.filters.statusNames.length == 3))
          searchKey = this.filters.statusNames
            .map(item => item || "Unknown")
            .join(", ");
        break;
      default:
        // nothing
        break;
    }

    return searchKey ? searchKey : "";
  }

  resetColumn(header) {
    this.loading = true;
    switch (header) {
      case "HARDWAREID":
        this.resetBy("hardwareId");
        break;
      case "SKU":
        this.resetBy("sku");
        break;
      case "ASSETNUMBER":
        this.resetBy("assetNumber");
        break;
      case "ASSETTAG":
        this.resetBy("assetTag");
        break;
      case "CATEGORYNAME":
        this.resetBy("category");
        break;
      case "PURCHASEID":
        this.resetBy("purchaseId");
        break;
      case "POID":
        this.resetBy("customerPO");
        break;
      case "ACCOUNTNAME":
        this.resetBy("customer");
        break;
      case "ENDUSER":
        this.filters.endUserNotNull = false;
        if (this.filters.endUser == "") {
          this.pageNumber = 1;
          this.fetchData();
        } else {
          this.resetBy("endUser");
        }
        break;
      case "WARRANTYNAME":
        this.resetBy("warrantyName");
        break;
      case "STARTDATEFORMATTED":
        this.resetSearchByDate("fromStartDate", "endStartDate");
        break;
      case "WARRENTYEXPFORMATTED":
        this.resetSearchByDate("fromExpDate", "endExpDate");
        break;
      case "PURCHASEDON":
        this.resetSearchByDate("fromPurchasedOn", "endPurchasedOn");
        break;
      case "LASTONLINE":
        this.filters.lastOnlineNotNull = false;
        this.resetSearchByDate("fromLastOnline", "endLastOnline");
        break;
      case "LASTCHECKEDFORMATTED":
        this.resetSearchByDate("fromLastCheckDate", "endLastCheckDate");
        break;
      case "PRODUCTLINE":
        this.resetBy("productLine");
        break;
      case "HSTATUS":
        this.resetStatusList();
        break;
      default:
        this.loading = true;
        break;
    }
  }

  async handleListChange(event) {
    this.sortSelectedHeader();
    const dataObj = {
      controller: "Users",
      FunctionName: "sortHardwareColumns",
      userid: sessionStorage.getItem("userId"),
      params: this.pageHeaders.map(item => item.key)
    };
    await axios.post(dataURL + "?ReturnType=JSON", dataObj);
  }

  getCityZip(hardwareData) {
    let ret: string[] = [];
    if (hardwareData.UCITY) {
      ret.push(hardwareData.UCITY);
    }
    if (hardwareData.USTATE || hardwareData.UZIP) {
      ret.push(
        `${hardwareData.USTATE || ""} ${hardwareData.UZIP || ""}`.trim()
      );
    }

    return ret.join(", ");
  }

  updateList(item) {
    const inList = this.items.find(t => t.HARDWAREID == item.hardwareId);
    if (inList) {
      if (item.type == "asset_tag") {
        inList.ASSETTAG = item.value;
      } else if (item.type == "asset_number") {
        inList.ASSETNUMBER = item.value;
      } else if (item.type == "warranty_name") {
        inList.WARRANTYNAME = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      } else if (item.type == "start_date") {
        inList.STARTDATEFORMATTED = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      } else if (item.type == "end_date") {
        inList.WARRENTYEXPFORMATTED = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      }
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  async searchByDate(fromField, endField) {
    this.pageNumber = 1;
    if (
      (this.filters[fromField] == undefined || this.filters[fromField] == "") &&
      (this.filters[endField] == "" || this.filters[endField] == undefined)
    ) {
      return;
    } else {
      this.searchFilters[fromField] = this.filters[fromField];
      this.searchFilters[endField] = this.filters[endField];
      await this.fetchData();
    }
  }

  async resetSearchByDate(fromField, endField) {
    this.pageNumber = 1;
    this.filters[fromField] = this.searchFilters[fromField] = "";
    this.filters[endField] = this.searchFilters[endField] = "";
    await this.fetchData();
  }

  getRangeFilter(from, to) {
    const arr: string[] = [];
    if (from) {
      arr.push(`From: ${from}`);
    }
    if (to) {
      arr.push(`To: ${to}`);
    }
    return arr.join("; ");
  }

  async checkWithIdentifiersOnly() {
    this.withIdentifiersOnly = !this.withIdentifiersOnly;
    await this.fetchData();
  }

  searchByHeader() {
    let filteredHeader = this.pageHeaders.filter(
      (item: any) =>
        item.value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1
    );
    if (this.viewType != "EndUser") {
      filteredHeader = filteredHeader.filter(
        item => item.key.toLowerCase() != "mainmodel"
      );
    }
    return filteredHeader;
  }

  clickOutsideHeader() {
    this.searchHeader = "";
  }

  updateSelected(item) {
    if (this.viewType != "All") return;

    if (this.selectedID.includes(item.HARDWAREID)) {
      // check
      const inList = this.items.find(t => t.HARDWAREID == item.HARDWAREID);
      this.selectedItems.push(inList);
    } else {
      // uncheck
      this.selectedItems = this.selectedItems.filter(
        t => t.HARDWAREID != item.HARDWAREID
      );
    }
  }

  checkColsWidth() {
    setTimeout(() => {
      if (
        (($(".tablesorter.hasResizable").attr("data-width") === "" &&
          $(".tablesorter.hasResizable").width() > 1440) ||
          $(".tablesorter.hasResizable").attr("data-width") > 1440) &&
        !$("body").hasClass("expand-full")
      ) {
        $("body").addClass("expand-full");
        $("span.expand-full").addClass("overflowed");
      } else if ($("body").hasClass("expand-full")) {
        if ($(".tablesorter.hasResizable").attr("data-width") > 1440) {
          $("body").addClass("expand-full");
          $("span.expand-full").addClass("overflowed");
        }
      }
    }, 1);
  }

  ifOverflowed() {
    if ($("body").hasClass("expand-full")) {
      setTimeout(() => {
        $("body").removeClass("expand-full");
        $("span.expand-full").removeClass("overflowed");
        if ($(".tablesorter.hasResizable").width() > 1440) {
          $("body").addClass("expand-full");
          $("span.expand-full").addClass("overflowed");
          notifier.alert(
            "Error: You have too many columns to return to standard view. Please remove columns to return to standard view"
          );
        }
      }, 1);
    }
  }

  showFilter = 0;
  selectAll(selectedAll = true) {
    this.hardwareCheckAll = selectedAll;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (const val of this.items) {
        if (!this.excludedIDs.includes(val.HARDWAREID)) {
          this.selectedID.push(val.HARDWAREID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }

  toggleEdit() {
    if (this.editableRows === true) {
      this.editableRows = false;
    } else {
      this.editableRows = true;
    }
  }

  updateCategory(categoryID, hardwareID) {
    const index = this.items.findIndex(item => item.HARDWAREID === hardwareID);
    const categoryName = this.categories.filter(item => item.ID === categoryID);

    if (index !== -1) {
      this.$set(this.items[index], "CATEGORYID", categoryID);
      this.$set(this.items[index], "CATEGORYNAME", categoryName[0].TEXT);
    } else {
      console.log("No item found with HARDWAREID:", hardwareID);
    }
  }

  updateStatus(statusID, hardwareID) {
    console.log(statusID, hardwareID);
    const index = this.items.findIndex(item => item.HARDWAREID === hardwareID);

    if (index !== -1) {
      this.$set(this.items[index], "ISACTIVE", statusID);
    } else {
      console.log("No item found with HARDWAREID:", hardwareID);
    }
  }

  async saveHardware(index) {
    this.isSaving = true;
    const item = this.items[index];
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      action: "inlineEdit",
      hardware: item,
      aId: item.AID || 0
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      this.isSaving = false;

      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      Vue.set(
        this.items[index],
        "LASTCHECKEDFORMATTED",
        mm + "/" + dd + "/" + yyyy
      );
    } else if (response.data.STATUS == 0) {
      notifier.alert(response.data.STATUSMESSAGE);
      this.isSaving = false;
    }
  }

  async saveUser(index) {
    this.isSaving = true;
    const item = this.items[index];
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      action: "saveEndUser",
      hardware: item,
      aId: item.AID || 0
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      this.isSaving = false;
    } else if (response.data.STATUS == 0) {
      notifier.alert(response.data.STATUSMESSAGE);
      this.isSaving = false;
    }
  }

  updateProgressBar() {
    const newWidth = (this.checkedWarranties / this.totalWarranties) * 100;
    this.animateProgressBar(newWidth);
  }

  animateProgressBar(targetWidth) {
    const duration = 25000;
    const startTime = performance.now();
    const startWidth = this.progressBarWidth;

    // Cancel any ongoing animation and jump to the targetWidth
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId); // Cancel the previous animation
    }

    // Update immediately if we want to skip animation
    if (this.progressBarWidth === targetWidth) {
      return; // No need to animate if already at the target
    }

    const animate = currentTime => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      this.progressBarWidth =
        startWidth + (targetWidth - startWidth) * progress;

      if (progress < 1) {
        // Store the ID so we can cancel it if needed
        this.animationFrameId = requestAnimationFrame(animate);
      } else {
        // Animation finished
        this.animationFrameId = null;
      }
    };

    // Jump immediately if the function is called again before the animation finishes
    if (targetWidth !== this.progressBarWidth) {
      this.progressBarWidth = targetWidth;
    }

    // Start the new animation
    this.animationFrameId = requestAnimationFrame(animate);
  }

  async checkWarranties() {
    let error = 0;
    this.totalWarranties = this.selectedItems.length;
    this.checkingWarranties = true;

    for (var counter = 0; counter < this.totalWarranties; counter++) {
      this.checkedWarranties = counter + 1;
      this.updateProgressBar();

      let result = await this.checkWarranty(this.selectedItems[counter]);
      if (result != 1) {
        error++;
      }
    }

    this.checkingWarranties = false;
  }

  async checkWarranty(item) {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "ExternalSends",
        FunctionName: "carepacks",
        serialNumber: item["ASSETNUMBER"],
        ProductSku: item["SKU"]
      });

      if (
        response.data.return !== undefined &&
        response.data.return["Coverage type"] !== undefined &&
        response.data.return["Coverage type"] !== ""
      ) {
        const updateReturn = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "UpdateHardware",
          action: "All",
          serialNumber: item["ASSETNUMBER"],
          ProductSku: item["SKU"],
          hardwareId: item["HARDWAREID"],
          hardwareUUID: item["HARDWAREUUID"],
          aId: item["AID"],
          warrantyName: response.data.return["Coverage type"],
          startDate: response.data.return["Start date"],
          endDate: response.data.return["End date"]
        });

        return updateReturn.data.STATUS;
      }
    } catch (error) {
      console.error(error);
    }
  }

  showAssignToEUModal() {
    // notify if assigned an end user before
    const tiedEUItems = this.selectedItems.find(item => item.EUID || 0);
    if (tiedEUItems) {
      this.confirmAssignVisible = true;
      return;
    }

    this.assignEndUsersModalVisible = true;
  }

  getPurchaseIds(item) {
    if ((this.viewType || "") == "EndUser") {
      return (item.PURCHASEIDS || "").split(",").join(", ");
    }

    return item.PURCHASEID;
  }

  get endUserColumns() {
    let ret = [
      "ENDUSER",
      "ADDRESS",
      "PHONE",
      "MAINMODEL",
      "PURCHASEID",
      "POID",
      "ACCOUNTNAME",
      "EMAIL",
      "DEVICES",
      "LASTONLINE",
      "LASTSEEN"
    ];
    if (this.currentRole == "Customer") {
      // ignore some columns
      ret = ret.filter(item => item != "ACCOUNTNAME");
    }
    return ret;
  }

  footerClickOutside(e) {
    // if ($(e.target).closest(".hardware-assign-eu-modal").length) {
    //   return;
    // }
    // this.modalHardwareDetails.show = false;
  }

  // System filter
  hasChangedStatusFilters = false;
  async checkStatusList(id, name) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.filters.status != undefined) {
      const findIndex = this.filters.status.findIndex(
        status => status === id
      );
      if (findIndex === -1) {
        this.filters.status.push(id);
      } else {
        this.filters.status.splice(findIndex, 1);
      }
    }
    if (this.filters.statusNames != undefined) {
      const findIndex = this.filters.statusNames.findIndex(
        status => status === name
      );
      if (findIndex === -1) {
        this.filters.statusNames.push(name);
      } else {
        this.filters.statusNames.splice(findIndex, 1);
      }
    }
    this.hasChangedStatusFilters = true;
  }
  async resetStatusList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.status = [];
    this.filters.statusNames = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllStatusList(allowFetchData);
  }
  async checkAllStatusList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.statusNames = [
      'Active',
      'Deleted',
      'Retired'
    ];
    this.filters.status = [0,1,2];
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async uncheckAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.status = [];
    this.filters.statusNames = [];
    await this.fetchData();
  }

  getStartDate(item, header) {
    if (header == "WARRENTYEXPFORMATTED") {
      return item.STARTDATEFORMATTED;
    }
  }

  checkDate(item) {
    if (item.STARTDATEFORMATTED > item.WARRENTYEXPFORMATTED) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  checkWarrantyDate(item) {
    if (item.value && moment(item.value, "MM/DD/YYYY", true).isValid()) {
      this.checkDate(item);
    }
  }
}
