



















import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";
import directives from "../helpers/directives";

import { InvoiceLineItem } from "../models/invoice";
import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { AccountList } from "../models/AccountList";

interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    account: AccountList.Accounts,
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
  directives
})
export default class CustomerAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  show?: boolean

  @Prop({ required: false, default : 0 })
  isDeployment?: boolean

  @Prop({ required: false, default: "" })
  targetYear?: string;

  @Prop({ required: false, default: "Customer"})
  placeholderText?: string;

  @Prop({ required: false, default: "bottom" })
  tooltipPosition!: string;

  @Prop({ required: false, default: false})
  titles?: string;

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: AccountList.Accounts[] = []
  URL:string = this.$parent.$route.path;

  createOptions(accounts: AccountList.Accounts[]) {
    const options = accounts.map(
      (acc): Option => {
        return {
          value: acc.AID.toString(),
          text: acc.ANAME
        };
      }
    )

    this.options = options
    this.searchData = accounts

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "FunctionName": "ListExt",
        "controller": "Accounts",
        "queryType": "top5UsedAcct",
        "isDeployment" : this.isDeployment,
        "targetYear" : this.targetYear
      });
      if (response.data.ERROR || !response.data.ACCOUNTS) {
        return {
          options: []
        };
      }

      return this.createOptions(response.data.ACCOUNTS)
    }

    const exportObj = {
      FunctionName: "List",
      controller: "Accounts",
      getAll: 1,
      search,
      targetYear: this.targetYear
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.ACCOUNTS) {
      return {
        options: []
      };
    }

    return this.createOptions(response.data.ACCOUNTS)
  };

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      //console.log('this.options', this.options)
      //console.warn(`no option found for value[${value}]`)
      return
    }
    const account = this.searchData.find((item) => item.AID.toString() === value)
    if (!account) {
      // console.warn(`no account found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      account,
    })
  }
}
